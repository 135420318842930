import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../store/NotificationSlice";
function NotificationMessage() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  const [show, setShow] = useState(false);
  useEffect(() => {
    let show_notification_timeout;
    if (notification) {
      setShow(true);
      show_notification_timeout = setTimeout(() => {
        setShow(false);
      }, 4000);
    }
    return () => {
      clearTimeout(show_notification_timeout);
    };
  }, [notification]);

  useEffect(() => {
    let clear_notification_timeout;
    if (!show) {
      clear_notification_timeout = setTimeout(() => {
        dispatch(clearNotification());
      }, 500);
    }
    return () => {
      clearTimeout(clear_notification_timeout);
    };
  }, [show, dispatch]);

  return (
    <motion.div
      initial={{ translateX: "100%" }}
      animate={{ translateX: show ? "-5%" : "100%" }}
      transition={{
        duration: 0.1,
        ease: "easeInOut",
        type: "spring",
        bounce: 0.25,
        stiffness: 50,
      }}
      className="border-secondary bg-primary z-40 text-secondary fixed right-0 bottom-8 regular-border py-2 px-8"
    >
      {notification ? notification : ""}
    </motion.div>
  );
}
export default NotificationMessage;
