import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import expandIcon from "../static/images/expand-icon.svg";
import axios from "axios";
import { api_url } from "../functions/constants";

const AdminSeoCard = ({ index, page, pageListSetter }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(page.title);
  const [url, setUrl] = useState(page.url);
  const [description, setDescription] = useState(page.description);
  const [keywords, setKeywords] = useState(page.keywords);
  const [meta_description, setMetaDescription] = useState(
    page.meta_description
  );
  const [meta_title, setMetaTitle] = useState(page.meta_title);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (
      title !== page.title ||
      url !== page.url ||
      description !== page.description ||
      keywords !== page.keywords ||
      meta_description !== page.meta_description ||
      meta_title !== page.meta_title
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [title, url, description, keywords, meta_description, meta_title]);

  function SubmitPage(e) {
    e.preventDefault();
    axios
      .put(
        api_url + "page",
        {
          title,
          url,
          description,
          keywords,
          meta_description,
          meta_title,
        },
        {
          params: {
            slug: page.url,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setIsModified(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DeletePage() {
    const alert_response = window.confirm(
      "Ești sigur că vrei să ștergi pagina " + title + "?"
    );
    if (alert_response) {
      axios
        .delete(api_url + "page", {
          params: {
            slug: page.url,
          },
        })
        .then((response) => {
          console.log(response.data);
          pageListSetter((prev) => prev.filter((item) => item.id !== page.id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: index * 0.25 }}
      key={page.id}
      className="p-4 bg-black/10 h-fit"
    >
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setShow(!show)}
      >
        <h2 className="text-lg font-semibold">
          {title} - {url}
        </h2>
        <motion.img
          animate={{ rotate: show ? 180 : 0 }}
          transition={{ duration: 0.2 }}
          className="w-5 h-5"
          src={expandIcon}
          alt="expand"
        />
      </div>
      <motion.form
        onSubmit={SubmitPage}
        initial={{ height: 0 }}
        animate={{ height: show ? "auto" : 0 }}
        transition={{ duration: 0.2 }}
        className="overflow-auto scrollbar-none"
      >
        <div className="mt-4 sm:grid-cols-1 grid grid-cols-2 gap-x-4 gap-y-2 ">
          <input
            className="!min-w-[10rem]"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder="Titlu pagină"
          />
          <input
            className="!min-w-[10rem]"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            placeholder="URL"
          />
          <textarea
            className="!min-w-[10rem]"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            rows={5}
            placeholder="Descriere pagină"
          />
          <textarea
            className="!min-w-[10rem]"
            onChange={(e) => setKeywords(e.target.value)}
            value={keywords}
            rows={5}
            placeholder="Cuvinte Cheie (separate prin virgulă)"
          />
          <textarea
            className="!min-w-[10rem]"
            onChange={(e) => setMetaDescription(e.target.value)}
            value={meta_description}
            rows={5}
            placeholder="Descriere meta"
          />
          <input
            className="!min-w-[10rem] h-fit"
            onChange={(e) => setMetaTitle(e.target.value)}
            value={meta_title}
            type="text"
            placeholder="Titlu meta"
          />
          <button
            type="button"
            onClick={DeletePage}
            className="bg-danger py-1 px-4 text-white w-full"
          >
            Șterge pagină
          </button>
          {isModified && (
            <button
              type="submit"
              className="bg-tertiary py-1 px-4 text-primary w-full"
            >
              Confirmă actualizările
            </button>
          )}
        </div>
        <div className="bg-white regular-border w-full p-4 mt-4">
          <div className="text-black/80 text-xs">
            {"scriptify.ro > " + title}
          </div>
          <h3 className="text-[#16167F] font-bold text-lg">
            {meta_title.length > 60
              ? meta_title.slice(0, 60) + " ..."
              : meta_title}
          </h3>
          <p className="text-sm text-black/90 font-medium ">
            {meta_description.length > 160
              ? meta_description.slice(0, 160) + " ..."
              : meta_description}
          </p>
        </div>
      </motion.form>
    </motion.div>
  );
};

export default AdminSeoCard;
