import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import edit from "../static/images/edit.svg";
import axios from "axios";
import { api_url } from "../functions/constants";
const CategoryCard = ({ category }) => {
  const [products, setProducts] = React.useState([]);
  useEffect(() => {
    axios
      .get(api_url + "portofolio/" + category.id + "/items")
      .then((response) => {
        setProducts(response.data);
      });
  }, []);

  return (
    <div className="">
      <h2 className="text-xl font-semibold flex justify-between items-center">
        {category.name}{" "}
        <Link to={"/admin/portofolio/category/" + category.id}>
          <img src={edit} className="w-4" />
        </Link>
      </h2>
      {(products.length == 0 && (
        <div className="bg-black/20 px-4 py-3 text-center">
          Nu există produse în această categorie. Adaugă una!
        </div>
      )) || (
        <div className="flex bg-black/20 py-2 px-2 flex-col gap-y-2">
          {products.map((product) => (
            <Link
              to={"/admin/portofolio/product/" + product.id}
              className="bg-primary p-2 hover:bg-primary/80 transition-colors duration-300 ease-in-out"
            >
              {product.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
