import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "./screens/admin/AdminLogin";
import Admin from "./screens/admin/Admin";
import AdminServices from "./screens/admin/AdminServices";
import AdminPortofolio from "./screens/admin/AdminPortofolio";
import AdminStatistics from "./screens/admin/AdminStatistics";
import AdminAddService from "./screens/admin/AdminAddService";
import AdminPortofolioNewProduct from "./screens/admin/AdminPortofolioNewProduct";
import AdminPortofolioNewCategory from "./screens/admin/AdminPortofolioNewCategory";
import AdminServiceQuestions from "./screens/admin/AdminServiceQuestions";
import AdminServicePackages from "./screens/admin/AdminServicePackages";
import Home from "./screens/Home";
import Services from "./screens/Services";
import Technologies from "./screens/Technologies";
import AboutUs from "./screens/AboutUs";
import FrequentlyAskedQuestions from "./screens/FrequentlyAskedQuestions";
import NavigationBar from "./components/NavigationBar";
import Serivce from "./screens/Service";
import PackageForm from "./screens/PackageForm";
import Contact from "./screens/Contact";
import ViewOrder from "./screens/ViewOrder";
import Order from "./screens/Order";
import AdminOrder from "./screens/admin/AdminOrder";
import AdminSEO from "./screens/admin/AdminSEO";
import AdminServiceEdit from "./screens/admin/AdminServiceEdit";
import AdminPackageEdit from "./screens/admin/AdminPackageEdit";
import Portofolio from "./screens/Portofolio";
import AdminPortofolioCategory from "./screens/admin/AdminPortofolioCategory";
import AdminPortofolioProduct from "./screens/admin/AdminPortofolioProduct";
import ErrorMessage from "./components/ErrorMessage";
import { Helmet } from "react-helmet-async";
import "./static/styles/output.css";
import "./static/images/manifest.json";
import "./static/images/favicon.ico";

import AdminRoute from "./components/AdminRoute";

import axios from "axios";
import { api_url } from "./functions/constants";
import NotificationMessage from "./components/NotificationMessage";
import AdminHome from "./screens/admin/AdminHome";
function App() {
  const [pageData, setPageData] = useState(null);
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageData(null);
    axios
      .get(api_url + "page", {
        params: {
          slug: location,
        },
      })
      .then((response) => {
        setPageData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location]);

  useEffect(() => {
    const startTime = new Date().getTime();
    const handleWebsiteExit = () => {
      const endTime = new Date().getTime();
      const timeSpent = endTime - startTime;
      console.log(timeSpent);
    };
    window.addEventListener("beforeunload", handleWebsiteExit);
  }, []);

  return (
    <div className="App w-screen overflox-x-hidden">
      {pageData ? (
        <Helmet>
          <title>{pageData.meta_title}</title>
          <meta name="description" content={pageData.meta_description} />
          <link rel="canonical" href={pageData.url} />
          <meta property="og:title" content={pageData.meta_title} />
          <meta property="og:description" content={pageData.meta_description} />
          <meta property="og:url" content={pageData.url} />
          <meta property="og:type" content="website" />
          <meta name="keywords" content={pageData.keywords} />
          {pageData.url.includes("Admin") || pageData.url.includes("admin") ? (
            <meta name="robots" content="noindex" />
          ) : (
            <meta name="robots" content="index" />
          )}
        </Helmet>
      ) : (
        <Helmet>
          <title>Scriptify - Creare website, aplicatii, chatbots</title>
          <meta
            name="description"
            content="Scriptify este dedicat dezvoltării de produse software ce îți vor transforma ideea în realitate. Creăm website-uri, chatbots, aplicații mobile, magazine shopify, website-uri wordpress și multe altele"
          />
          <link rel="canonical" href="/" />
        </Helmet>
      )}
      <ErrorMessage />
      <NotificationMessage />
      <Routes>
        <Route path="" element={<NavigationBar />}>
          <Route path="/" element={<Home />} />
          <Route
            path="services"
            exact={true}
            element={
              <Services
                title={pageData ? pageData.title : "Serviciile oferite de noi"}
                description={
                  pageData
                    ? pageData.description
                    : "Citește mai multe despre serviciile oferite de noi. Creăm website-uri, chatbots, aplicații mobile și multe altele"
                }
              />
            }
          />
          <Route path="technologies" exact={true} element={<Technologies />} />
          <Route path="about-us" exact={true} element={<AboutUs />} />
          <Route path="portofolio" exact={true} element={<Portofolio />} />
          <Route
            path="faq"
            exact={true}
            element={
              <FrequentlyAskedQuestions
                title={pageData ? pageData.title : "Întrebări frecvente"}
                description={
                  pageData
                    ? pageData.description
                    : "Întrebări frecvente despre serviciile noastre. Dacă întrebarea ta nu se regăsește aici, nu ezita să ne contactezi."
                }
              />
            }
          />
          <Route path="contact" exact={true} element={<Contact />} />
          <Route path="package" exact={true} element={<PackageForm />} />
          <Route path="order" exact={true} element={<ViewOrder />} />
          <Route path="order/:id" element={<Order />} />

          <Route
            path="service/:id"
            element={
              <Serivce
                title={pageData ? pageData.title : "Alege package-ul potrivit"}
                description={
                  pageData
                    ? pageData.description
                    : "Alege pachetul care vi se potrivește sau optați pentru un produs personalizat."
                }
              />
            }
          />
        </Route>

        <Route
          path="/admin"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        >
          <Route path="" element={<AdminServices />} />
          <Route path="seo" element={<AdminSEO />} />
          <Route path="add-service" element={<AdminAddService />} />
          <Route path="home" element={<AdminHome />} />
          <Route
            path="service/:id/questions"
            element={<AdminServiceQuestions />}
          />
          <Route
            path="service/:id/packages"
            element={<AdminServicePackages />}
          />
          <Route path="order/:id" element={<AdminOrder />} />
          <Route path="service/:id/edit" element={<AdminServiceEdit />} />
          <Route
            path="service/:id/package/:package_id/edit"
            element={<AdminPackageEdit />}
          />
          <Route path="portofolio" element={<AdminPortofolio />} />
          <Route
            path="portofolio/product/add"
            element={<AdminPortofolioNewProduct />}
            exact={true}
          />
          <Route
            path="portofolio/category/add"
            element={<AdminPortofolioNewCategory />}
            exact={true}
          />
          <Route
            path="portofolio/category/:id"
            element={<AdminPortofolioCategory />}
          />
          <Route
            path="portofolio/product/:id"
            element={<AdminPortofolioProduct />}
          />

          <Route path="statistics" element={<AdminStatistics />} />
        </Route>
        <Route path="/admin/login" exact={true} element={<AdminLogin />} />
      </Routes>
    </div>
  );
}

export default App;
