import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { api_url, auth_url } from "../functions/constants";
import { useLocation, useNavigate } from "react-router-dom";
import get_auth_config from "../functions/GetAuthConfig";
function AdminRoute({ children }) {
  const admin = useSelector((state) => state.admin);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const token = admin.access;
    console.log(token);

    if (!token) {
      navigate("/admin/login");
      return;
    }
    axios
      .post(auth_url + "jwt/verify/", {
        token,
      })
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        navigate("/admin/login");
      });
  }, [location.pathname, admin.token]);

  return <div>{isAdmin ? children : ""}</div>;
}

export default AdminRoute;
