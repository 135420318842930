import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../../functions/constants";
import { motion } from "framer-motion";
import AdminSeoCard from "../../components/AdminSeoCard";
import { setPage } from "../../store/AdminPageSlice.js";
import { useDispatch } from "react-redux";
const AdminSEO = () => {
  const [pages, setPages] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPage("Optimizare SEO"));
    axios
      .get(api_url + "pages")
      .then((response) => {
        setDataLoaded(true);
        setPages(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function AddPageHandler(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const title = data.get("title");
    const url = data.get("url");
    if (!title || !url) {
      return;
    }
    axios
      .post(api_url + "page", {
        title: title,
        slug: url,
      })
      .then((response) => {
        const new_page = response.data;
        setPages([...pages, new_page]);
        e.target.reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="px-20 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-4">
      <div className="grid grid-cols-2 gap-x-10 lg:gap-x-5 md:grid-cols-1 sm:grid-cols-1 gap-y-5 w-full">
        {pages.map((page, index) => (
          <AdminSeoCard pageListSetter={setPages} index={index} page={page} />
        ))}
        <motion.form
          initial={{ opacity: 0 }}
          animate={{ opacity: dataLoaded ? 1 : 0 }}
          transition={{ delay: pages.length * 0.25 }}
          onSubmit={AddPageHandler}
          className="p-4 bg-black/10 h-fit w-full sm:grid-cols-1 grid grid-cols-2 gap-x-4 gap-y-2"
        >
          <input
            className="!min-w-[10rem]"
            name="title"
            type="text"
            placeholder="Titlul paginii"
          />
          <input
            className="!min-w-[10rem]"
            name="url"
            type="text"
            placeholder="URL"
          />
          <button type="submit" className="regular-btn text-base">
            Adaugă pagină
          </button>
        </motion.form>
      </div>
    </div>
  );
};

export default AdminSEO;
