import { configureStore } from "@reduxjs/toolkit";
import PackageSlice from "./PackageSlice";
import ErrorSlice from "./ErrorSlice";
import AdminSlice from "./AdminSlice";
import OrderSlice from "./OrderSlice";
import AdminPageSlice from "./AdminPageSlice";
import NotificationSlice from "./NotificationSlice";
export const store = configureStore({
  reducer: {
    package: PackageSlice,
    error: ErrorSlice,
    admin: AdminSlice,
    order: OrderSlice,
    adminPage: AdminPageSlice,
    notification: NotificationSlice,
  },
});
