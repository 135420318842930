import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { api_url } from "../../functions/constants.js";
import plus from "../../static/images/plus.svg";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageNotLoaded from "../../components/PageNotLoaded";
import { useSelector } from "react-redux";
import get_auth_config from "../../functions/GetAuthConfig.js";
function AdminServiceEdit() {
  const admin = useSelector((state) => state.admin);
  const config = get_auth_config(admin.token);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const id = useParams().id;
  const [service, setService] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [serviceIcon, setServiceIcon] = useState(null);
  const [guarantees, setGuarantees] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "service/" + id)
      .then((response) => {
        setService(response.data);
        setServiceName(response.data.name);
        axios
          .get(api_url + "service/" + id + "/guarantees")
          .then((response) => {
            setGuarantees(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setPageError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);
  function AddServiceIconHandler(e) {
    e.preventDefault();
    const icon = e.target.files[0];
    const type = icon.type;
    if (!type.includes("svg")) {
      alert("Formatul imaginii trebuie să fie SVG");
      return;
    }
    setServiceIcon(icon);
  }
  function EditServiceHandler(e) {
    e.preventDefault();
    const data = e.target;
    const name = data.name.value;
    const description = data.description.value;
    const starting_price = data.starting_price.value;
    let discount_price = data.discount_price.value;
    if (Number(discount_price) > Number(starting_price)) {
      alert("Prețul redus nu poate fi mai mare decât prețul inițial");
      return;
    }

    if (discount_price === "") {
      discount_price = 0;
    }

    if (name === "" || description === "" || starting_price === "") {
      alert("Toate câmpurile sunt obligatorii");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("starting_price", starting_price);
    formData.append("discount_price", discount_price);

    if (serviceIcon != null) {
      formData.append("icon", serviceIcon);
    }
    const multipart_config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + admin.token,
      },
    };
    axios
      .put(api_url + "service/" + id, formData, multipart_config)
      .then((response) => {
        axios
          .put(api_url + "service/" + id + "/guarantees", guarantees, config)
          .then((response) => {
            navigate("/admin");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AddGuaranteeHandler(e) {
    e.preventDefault();
    const data = e.target;
    const new_guarantee = data.guarantee.value;
    if (new_guarantee === "") {
      alert("Câmpul nu poate fi gol");
      return;
    }
    const new_guarantees = [
      ...guarantees,
      { id: guarantees.length + 1, name: new_guarantee },
    ];
    setGuarantees(new_guarantees);
    data.guarantee.value = "";
  }

  function RemoveGuaranteeHandler(e, id) {
    e.preventDefault();
    const new_guarantees = guarantees.filter(
      (guarantee) => guarantee.id !== id
    );
    setGuarantees(new_guarantees);
  }

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div className="w-full px-16 mx-auto mt-8 xl:px-10 lg:px-10 md:px-10 sm:px-5 mb-10">
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>Admin | {serviceName}</title>
      </Helmet>
      <h1 className="w-fit text-2xl">{serviceName} | Editare Serviciu</h1>
      <div className="flex mt-4 gap-x-4 justify-between md:flex-col sm:flex-col lg:flex-col gap-y-8">
        <form
          className="flex gap-x-4 md:flex-col sm:flex-col"
          onSubmit={EditServiceHandler}
        >
          <div className="flex-col flex w-1/2 xl:w-1/2 lg:w-[60%] gap-y-2 md:w-full sm:w-full">
            <input
              className="sm:!min-w-[15rem]"
              type="text"
              onChange={(e) => setServiceName(e.target.value)}
              placeholder="Nume"
              name="name"
              defaultValue={serviceName}
            />
            <textarea
              className="min-h-[15rem] sm:!min-w-[15rem] !min-w-[30rem] overflow-y-auto default-scroll"
              name="description"
              placeholder="Descriere"
              defaultValue={service ? service.description : ""}
            ></textarea>
            <button className="regular-btn w-fit px-8 pt-1 block md:hidden sm:hidden text-center">
              Aplică modificările
            </button>
          </div>
          <span className="flex gap-x-4 md:mt-4 sm:mt-4">
            <div>
              <input
                type="file"
                className="hidden"
                id="packageIcon"
                onChange={AddServiceIconHandler}
              />
              <label
                htmlFor="packageIcon"
                className="cursor-pointer w-fit block"
              >
                <div className="h-40 w-40 regular-border relative">
                  <img
                    alt={"service-icon"}
                    src={
                      serviceIcon == null
                        ? service
                          ? "data:image/svg+xml;base64," + service.icon
                          : plus
                        : URL.createObjectURL(serviceIcon)
                    }
                    className={
                      "top-1/2 absolute-center" +
                      (serviceIcon == null && service == null
                        ? " w-1/3"
                        : " w-2/3  text-white")
                    }
                  />
                </div>
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <input
                name="starting_price"
                type="text"
                className="h-fit sm:!min-w-[5rem]"
                placeholder="Preț de start (€)"
                defaultValue={service ? service.starting_price : ""}
              />
              <input
                name="discount_price"
                type="text"
                className="h-fit sm:!min-w-[5rem]"
                placeholder="Preț de discount (€)"
                defaultValue={service ? service.discount_price : 0}
              />
            </div>
          </span>
          <button className="regular-btn w-fit px-8 pt-1 hidden md:block mt-4 ml-auto sm:block sm:w-full text-center">
            Aplică modificările
          </button>
        </form>
        <form
          className="w-[25%] lg:w-1/2 lg:mr-0 lg:ml-auto md:ml-auto md:mr-0 md:w-1/2 mr-auto sm:w-full"
          onSubmit={AddGuaranteeHandler}
          id="add_guarantees_form"
        >
          <div className=" relative w-full">
            <input
              type="text"
              placeholder="Garanție serviciu"
              className="!w-full !min-w-[15rem]"
              name="guarantee"
            />
            <button className="regular-btn absolute h-full px-4 right-0">
              Adaugă
            </button>
          </div>
          <div className="mt-2 regular-border h-40">
            <ul className="h-full overflow-y-auto default-scroll">
              {guarantees.map((guarantee, index) => {
                return (
                  <li
                    key={index}
                    className="select-text flex justify-between items-center h-5 mt-2 px-4"
                  >
                    <p className="truncate">{guarantee.name}</p>
                    <button
                      onClick={(e) => RemoveGuaranteeHandler(e, guarantee.id)}
                      className="text-2xl leading-0"
                    >
                      x
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminServiceEdit;
