import React from "react";
import ribbon from "../static/images/ribbon.svg";
import LeftColorButton from "./LeftColorButton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function ServiceCard({
  index,
  id,
  name,
  description,
  starting_price,
  discount_price,
  icon,
  guarantees,
}) {
  console.log(discount_price);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: index * 0.25 } }}
      className="min-w-[20rem] regular-border flex flex-col"
    >
      <div className="p-4 relative">
        <h2 className="text-dark font-semibold text-2xl">{name}</h2>
        <p
          className="font-medium"
          dangerouslySetInnerHTML={{
            __html: description.replace("\n", "<br/><br/>"),
          }}
        />
      </div>
      <div className="bg-secondary flex sm:flex-col lg:flex-col lg:gap-y-4 sm:gap-y-4 p-4 gap-x-4 mt-auto relative">
        {starting_price > 0 && (
          <div className="lg:h-56 sm:hidden absolute top-0 h-72 translate-x-[-50%] z-20 right-0 xl:translate-x-[-10%]">
            <img
              alt="price-ribbon"
              src={ribbon}
              className="h-full translate-y-[-3%]"
            />
            <div className="lg:text-base absolute-center text-center translate-y-[-75%] font-bold text-lg">
              <div className="text-xl lg:text-lg">
                DE <br /> LA <br />
              </div>
              {discount_price > 0 ? (
                <span>
                  €{discount_price}
                  <br />
                  <s className="text-lg">€{starting_price}</s>
                </span>
              ) : (
                <span>€{starting_price}</span>
              )}
            </div>
          </div>
        )}
        <span className="sm:flex justify-between">
          <div className="h-40 w-40 py-5 px-5 bg-primary regular-border">
            <img
              alt="service-icon"
              src={"data:image/svg+xml;base64," + icon}
              className="h-full mx-auto gap-4"
            />
          </div>
          <div className="hidden sm:block text-secondary bg-primary font-medium h-fit grow regular-border p-2 text-xl text-center">
            DE LA <b>€{starting_price}</b>
          </div>
        </span>
        <div className="">
          <p className="text-primary text-xl">Pentru orice pachet garantăm:</p>
          <ul className="list-disc list-inside">
            {guarantees &&
              guarantees.map((guarantee) => (
                <li className={"text-primary"} key={guarantee.id}>
                  {guarantee.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Link to={"/service/" + id}>
        <LeftColorButton>ALEGE PACHETUL CARE ȚI SE POTRIVEȘTE</LeftColorButton>
      </Link>
    </motion.div>
  );
}

export default ServiceCard;
