import React, { useEffect, useState } from 'react'

function LeftColorButton({children, absolute=false, text_center=false}) {
  const [isTooLong, setIsTooLong] = useState(false);
  useEffect(() => {
    if(children.length > 35 && text_center){
      setIsTooLong(true);
    }
  }, [children, text_center])
  return (
    <div className={'block group regular-btn w-full cursor-pointer' + (absolute ? " bottom-0 left-0 absolute" : " relative") + (text_center ? " text-center" : " text-left")}>
        <div className={'opacity-0 px-4 pt-2 pb-0.5' + (isTooLong ? " text-sm" : "")}>{children}</div>
        <div className={'font-semibold absolute px-4 pt-2 pb-0.5 z-10 bottom-0 w-full' + (text_center ? " left-1/2 translate-x-[-50%]" : " left-0") + (isTooLong ? " text-sm" : "")}>{children}</div>
        <div className='group-hover:w-full bottom-0 left-0 w-0 bg-dark ease-in-out transition-all duration-500 absolute scrollbar-none h-full'></div>
    </div>
  )
}

export default LeftColorButton