function DateTimeConvertor(python_datetime){
    // convert the python datetime to js datetime dd/mm/yyyy hh:mm:ss
    let date = python_datetime.split("T")[0]
    //make the date in the format dd/mm/yyyy
    let date_list = date.split("-")
    let year = date_list[0]
    let month = date_list[1]
    let day = date_list[2]
    date = day + "/" + month + "/" + year
    //make the time in the format hh:mm:ss
    let time = python_datetime.split("T")[1]
    time = time.split(".")[0]
    return date + " " + time
}
export default DateTimeConvertor