import React, { useState, useEffect } from "react";
import play_btn from "../static/images/play-btn.svg";
import home_desk from "../static/images/home-desk.svg";
import { api_url, faq, reviews } from "../functions/constants";
import axios from "axios";
import { motion } from "framer-motion";
import { video_url } from "../functions/constants";
import ChangingQuote from "../components/ChangingQuote";
import { Link } from "react-router-dom";
import LeftColorButton from "../components/LeftColorButton";
import LoadingSpinner from "../components/LoadingSpinner";
import PageNotLoaded from "../components/PageNotLoaded";
import scriptify_thumbnail from "../static/images/scriptify-thumbnail.svg";
import Review from "../components/Review";
import projects_icon from "../static/images/projects-icon.svg";
import global_icon from "../static/images/global-icon.svg";
import calendar_icon from "../static/images/calendar-icon.svg";
import useMediaQuery from "../hooks/useMediaQuery";

import HistoryProjects from "../components/HistoryProjects";
import Achievement from "../components/Achievement";
function Home() {
  const isMD = useMediaQuery("(max-width: 900px)");
  const isSM = useMediaQuery("(max-width: 640px)");
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [services, setServices] = useState([]);
  const [isDeskGuyLoading, setIsDeskGuyLoading] = useState(true);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setIsDeskGuyLoading(false);
    axios
      .get(api_url + "services")
      .then((response) => {
        setServices(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setPageError(true);
      });


    axios.get(api_url + "home/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  function PlayPresentation() {
    const video = document.getElementById("presentation_video");
    if (isVideoPlaying) {
      video.pause();
      setIsVideoPlaying(false);
      return;
    }
    video.volume = 0.5;
    video.play();
    setIsVideoPlaying(true);
  }

  return (
    <div>
      <motion.div
        initial={{ height: "100vh" }}
        animate={
          isDeskGuyLoading
            ? {}
            : { height: "auto", transition: { duration: 0.4, delay: 0.1 } }
        }
        className="flex sm:flex-col sm:px-4 sm:gap-y-36 md:flex-col md:gap-y-36 justify-between sm:mt-0 md:mt-0 mt-20 px-36 xl:px-24 lg:px-6 "
      >
        <motion.img
          initial={{ x: isSM ? 0 : -100, opacity: 0, width: "30rem" }}
          animate={
            isDeskGuyLoading
              ? {}
              : {
                  x: 0,
                  opacity: 100,
                  transition: { duration: 0.4, delay: 0.3 },
                }
          }
          className="w-[30rem] block sm:order-2 sm:mx-auto md:order-2 md:mx-auto"
          src={home_desk}
          alt="Home deks"
        />
        <div className="w-[calc(100%-30rem)] md:w-full sm:w-full">
          <h1 className="flex mx-auto w-fit text-3xl font-semibold mt-12 text-center">
            Platformă de creare și dezvoltare a afacerii tale online
          </h1>
          <h2 className="flex mx-auto w-fit text-xl font-normal mt-2 text-center">
            TRANSFORMĂM IDEILE ÎN SUCCES
          </h2>
          <Link to="/services" className="w-fit block mx-auto sm:w-full">
            <div className="w-1/2 min-w-[30rem] sm:min-w-[20rem] sm:w-full mx-auto mt-8">
              <LeftColorButton text_center={true}>
                CONSTRUIEȘTE-MI AFACEREA ONLINE{" "}
              </LeftColorButton>
            </div>
          </Link>
        </div>
      </motion.div>
      <hr className="regular-border border-1 translate-y-[-1px]" />
      <div className="min-h-[15rem] relative transition-all duraiton-300 ease-in-out">
        {pageError ? (
          <PageNotLoaded className="absolute-center">
            A apărut o problemă la încărcarea serviciilor. O vom rezolva cât mai
            curând posibil.
          </PageNotLoaded>
        ) : isLoading ? (
          <LoadingSpinner className={"absolute-center"} />
        ) : (
          <div className="px-20 py-12 gap-y-8 grid grid-cols-5 lg:px-8 md:px-12 md:grid-cols-3 sm:grid-cols-2 sm:px-4">
            {services &&
              services.length > 0 &&
              services.map((service, index) => {
                return (
                  <Link to={"/service/" + service.id} key={service.id}>
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { delay: index * 0.25 },
                      }}
                    >
                      <div className="regular-border h-40 w-40 p-6 mx-auto relative group cursor-pointer">
                        <img
                          alt={service.name}
                          className="h-full mx-auto"
                          src={"data:image/svg+xml;base64," + service.icon}
                        />
                        <div className="absolute bottom-0 left-0 bg-secondary w-full group-hover:h-4 h-0 transition-all ease-in-out duration-200"></div>
                      </div>
                      <h3 className="text-xl mt-2 cursor-pointer text-center">
                        {service.name}
                      </h3>
                    </motion.span>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
      <div className="px-36 pb-12 lg:px-8 xl:px-16 md:px-12 sm:px-8 ">
        <h2 className="text-dark w-fit mx-auto text-2xl text-center">
          Cifrele{!isSM ? " noastre" : ""} vorbesc de la sine.{" "}
          {isMD ? <br /> : ""} {isSM ? "D" : "Află d"}e ce ar trebui să lucrezi
          cu noi:
        </h2>
        <div className="flex gap-x-20 justify-between mt-8 md:flex-col md:gap-y-4 sm:flex-col sm:gap-y-4">
          <Achievement
            order={1}
            img_src={projects_icon}
            h1={"100+ Clienți satisfăcuți"}
            p={
              "Am ajutat peste 100 de clienți ce au apelat la serviciile noastre."
            }
          />
          <Achievement
            order={2}
            img_src={global_icon}
            h1={"Proiecte la nivel internațional"}
            p={"Am colaborat cu persoane din România și toată Europa."}
          />
          <Achievement
            order={3}
            img_src={calendar_icon}
            h1={"7 ani de experiență"}
            p={
              "Ne dezvoltăm ca programatori de 7 ani. Scriptify a început în 2023."
            }
          />
        </div>
      </div>
      <HistoryProjects />
      <hr className="border-primary border-1 border-solid" />

      <div className="bg-priamry"></div>
      <div className="px-36 py-12 bg-tertiary lg:px-8 xl:px-16 md:px-8 sm:px-8">
        <ChangingQuote />
        <Link
          to="/faq"
          className="w-fit bg-primary px-8 pb-1 pt-2 mx-auto flex min-w-[20rem] justify-center mt-12 text-xl font-medium"
        >
          Întrebări frecvente
        </Link>
        <div className="flex lg:flex-col lg:gap-y-4 md:flex-col md:gap-y-4 justify-between mt-8">
          <div className="w-1/2 sm:hidden lg:w-full md:w-full flex flex-col gap-y-6">
            {faq.map((question, index) => {
              return (
                <div key={index}>
                  <p
                    onClick={() =>
                      selectedQuestion === index
                        ? setSelectedQuestion(null)
                        : setSelectedQuestion(index)
                    }
                    className="text-primary cursor-pointer text-lg font-medium"
                  >
                    {question.question}
                  </p>
                  <motion.p
                    initial={{ height: 0 }}
                    animate={{
                      height: index === selectedQuestion ? "auto" : "0",
                    }}
                    className="scrollbar-none 	overflow-y-auto text-primary"
                  >
                    {question.answer}
                  </motion.p>
                </div>
              );
            })}
          </div>

          <div className="w-[44%] sm:w-full lg:w-[50rem] sm:min-w-[20rem] md:w-full md:mt-4 lg:mx-auto min-w-[25rem] h-fit relative border-primary border-solid border-4">
            <video
              poster={scriptify_thumbnail}
              onClick={PlayPresentation}
              loop
              playsInline
              className="w-full"
              id="presentation_video"
            >
              <source src={video_url} type="video/mp4" />
            </video>
            <motion.img
              animate={{
                opacity: isVideoPlaying ? 0 : 1,
                cursor: isVideoPlaying ? "auto" : "pointer",
              }}
              onClick={PlayPresentation}
              src={play_btn}
              className="w-20 h-20 sm:w-14 sm:h-14 absolute-center z-10"
            />
          </div>
        </div>
      </div>
      <div className={`flex overflow-x-hidden mb-10 mt-4`}>
        <div
          className={`slider-track flex w-[calc(40rem * 6)] md:w-[calc(30rem * 6)] sm:w-[calc(20rem * 6)] lg:w-[calc(35rem * 6)]`}
        >
          {reviews.map((review, index) => {
            return <Review key={index} review={review} />;
          })}
        </div>
        <div
          className={`flex slider-track w-[calc(40rem * 6)] md:w-[calc(30rem * 6)] sm:w-[calc(20rem * 6)] lg:w-[calc(35rem * 6)]`}
        >
          {reviews.map((review, index) => {
            return <Review key={index} review={review} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
