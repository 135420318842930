import React, { useEffect, useState } from "react";
import plus from "../../static/images/plus.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../../functions/constants.js";
import DateTimeConvertor from "../../functions/DateTimeConvertor";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageNotLoaded from "../../components/PageNotLoaded";
import { useSelector, useDispatch } from "react-redux";
import get_auth_config from "../../functions/GetAuthConfig.js";
import { setPage } from "../../store/AdminPageSlice.js";
function AdminServices() {
  const admin = useSelector((state) => state.admin);
  const config = get_auth_config(admin.access);

  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPage("Servicii și comenzi"));
    setIsLoading(true);
    axios
      .get(api_url + "services")
      .then((response) => {
        const response_services = response.data;
        setServices(response_services);
      })
      .catch((error) => {
        setPageError(true);
        setIsLoading(false);
      });

    axios
      .get(api_url + "orders", config)
      .then((response) => {
        setIsLoading(false);
        const response_orders = response.data;
        setOrders(response_orders);
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  function DeleteServiceHandler() {
    if (selectedService) {
      const confirmation = window.confirm(
        "Ești sigur că vrei să ștergi serviciul " + selectedService.name + "?"
      );
      if (confirmation)
        axios
          .delete(api_url + "service/" + selectedService.id, config)
          .then((response) => {
            setSelectedService(null);
            setServices(
              services.filter((service) => service.id !== selectedService.id)
            );
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }

  return (
    <section className="mt-12 w-2/3 xl:w-3/4 lg:w-[90%] md:w-[90%] sm:w-[90%] mx-auto">
      <div className="flex flex-wrap">
        {services &&
          services.map((service, index) => {
            return (
              <button
                key={index}
                onClick={() => setSelectedService(service)}
                className="relative group w-24 h-24 bg-transparent border-secondary border-solid border-2 border-r-1 p-3"
              >
                <div
                  className={
                    "absolute left-0 bottom-0 bg-secondary w-full h-0 group-hover:h-2 transition-all ease-in-out duration-300" +
                    (selectedService === service ? " h-2" : "")
                  }
                ></div>
                <img
                  key={service.id}
                  src={"data:image/svg+xml;base64," + service.icon}
                  alt={service.name}
                  className="z-10 h-16 mx-auto"
                />
              </button>
            );
          })}
        <Link to={"/admin/add-service"}>
          <button className="w-24 group h-24 bg-transparent border-secondary border-solid border-2 p-8">
            <img
              src={plus}
              alt="Adaugă serviciu"
              className="w-fit group-hover:scale-125 ease-in-out transition-transform duration-300"
            />
          </button>
        </Link>
      </div>
      <div className="relative border-2 border-solid min-h-[30rem] overflow-y-auto default-scroll border-secondary translate-y-[-2px]">
        {pageError ? (
          <PageNotLoaded className="absolute-center">
            A apărut o eroare la încărcarea paginii.
          </PageNotLoaded>
        ) : isLoading ? (
          <LoadingSpinner className={"absolute-center"} />
        ) : (
          <div className="p-4 grid gap-8 grid-cols-4 lg:grid-cols-3 md:grid-cols-2 items-start sm:grid-cols-1">
            {orders.map((order, index) => (
              <Link
                key={index}
                to={"/admin/order/" + order.order_id}
                className="w-full regular-border h-fit p-2 relative group cursor-pointer"
              >
                <div className="absolute left-0 bottom-0 h-0 w-full group-hover:h-full bg-secondary transition-all ease-in-out duration-300"></div>
                <h2 className="font-bold text-xl relative group-hover:text-primary duration-300 ease-in-out transition-colors select-text">
                  #{order.order_id}
                </h2>
                <p className="text-sm relative group-hover:text-primary duration-300 ease-in-out transition-colors select-text">
                  Client:{" "}
                  {order.client_company.length > 0
                    ? order.client_company
                    : order.client_name}
                </p>
                <p className="text-sm relative group-hover:text-primary duration-300 ease-in-out transition-colors select-text">
                  Email: {order.client_email}
                </p>
                <p className="opacity-75 text-xs text-right mt-2 group-hover:text-primary duration-300 ease-in-out transition-colors">
                  {DateTimeConvertor(order.created_at)}
                </p>
              </Link>
            ))}
          </div>
        )}
      </div>
      <motion.div
        className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 sm:mt-4 gap-y-4 justify-between gap-x-4 translate-y-[-2px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: selectedService ? 1 : 0 }}
      >
        <Link
          to={
            selectedService &&
            "/admin/service/" + selectedService.id + "/questions"
          }
        >
          <button className="regular-btn pt-1 w-full">
            Vezi întrebări frecvente
          </button>
        </Link>
        <Link
          to={
            selectedService && "/admin/service/" + selectedService.id + "/edit"
          }
        >
          {" "}
          <button className="regular-btn pt-1 w-full">Editează serviciu</button>
        </Link>
        <Link
          to={
            selectedService &&
            "/admin/service/" + selectedService.id + "/packages"
          }
        >
          <button className="regular-btn first:pt-1 w-full">
            Vezi pachete
          </button>
        </Link>
        <button
          onClick={DeleteServiceHandler}
          className="regular-btn pt-1 w-full bg-red-800"
        >
          Șterge serviciu
        </button>
      </motion.div>
    </section>
  );
}

export default AdminServices;
