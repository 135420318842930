import React from 'react'

export const AddPackageQuestionCard = ({id, question, removeHandler}) => {
  return (
    <div className='relative regular-border p-2'>
        <button onClick={(e)=>removeHandler(id)} className='text-2xl leading-0 absolute top-0 right-1'>x</button>
        <div className='text-lg font-semibold mb-1'>
            <span className='text-primary inline-block bg-secondary whitespace-nowrap h-fit px-2 mr-2 pt-0.5'>
                {question.type === "short" ? "Text scurt" : question.type === "long" ? "Text lung" : 
                question.type === "options" ? "Variante multiple": "unknown"}
            </span>
            <span className='pt-0.5'>{question.question}</span>
        </div>
        {question.type === "options" && <div className='flex flex-col gap-y-2'>
            {question.options.map((option, index) => {
                return <div key={index} className='flex  px-2 justify-between items-center'>
                    <p className='text-dark'>{option}</p>
                    </div>
            })}
        </div>}
    </div>
  )
}

export default AddPackageQuestionCard