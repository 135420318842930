import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Achievement = ({ img_src, h1, p, order }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const achievementVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: (order - 1) / 3 },
    },
    hidden: { opacity: 0, scale: 0.95 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={achievementVariants}
      className="flex gap-x-4 w-1/3 md:w-full sm:w-full"
    >
      <img src={img_src} className="w-[6rem] rounded-xl" />
      <div className="flex flex-col">
        <h3 className="text-xl font-semibold">{h1}</h3>
        <p className="text-lg">{p}</p>
      </div>
    </motion.div>
  );
};

export default Achievement;
