import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../functions/constants";
import play_btn from "../static/images/play-btn.svg";
import { Link } from "react-router-dom";
import DateTimeConvertor from "../functions/DateTimeConvertor";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../components/LoadingSpinner";
import PageNotLoaded from "../components/PageNotLoaded";
import { useSelector } from "react-redux";
function Order() {
  const order = useSelector((state) => state.order);
  const code = useParams().id;
  const [orderInfo, setOrderInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}order/${code}?email=${order.email}`)
      .then((res) => {
        const response_order = res.data;
        response_order.written_project_modified_at =
          response_order.written_project_modified_at
            ? DateTimeConvertor(response_order.written_project_modified_at)
            : null;
        setOrderInfo(response_order);
        setIsLoading(false);
      })
      .catch((err) => {
        setPageError(true);
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  function WrittenProjectAcceptHandler(e) {
    e.preventDefault();
    axios
      .put(`${api_url}order/${code}?email=${order.email}`, {
        written_project_accepted: true,
      })
      .then((res) => {
        setOrderInfo({ ...orderInfo, written_project_accepted: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function VisualProjectAcceptHandler(e) {
    e.preventDefault();
    axios
      .put(`${api_url}order/${code}?email=${order.email}`, {
        visual_project_accepted: true,
      })
      .then((res) => {
        setOrderInfo({ ...orderInfo, visual_project_accepted: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ProjectAcceptHandler(e) {
    e.preventDefault();
    axios
      .put(`${api_url}order/${code}?email=${order.email}`, {
        project_accepted: true,
      })
      .then((res) => {
        setOrderInfo({ ...orderInfo, project_accepted: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>Detalii comandă {orderInfo.name ? orderInfo.name : ""}</title>
      </Helmet>
      {pageError ? (
        <PageNotLoaded className="absolute-center">
          Nu sunteți autorizat să vizualizați această pagină. Introduceți datele
          corecte.
        </PageNotLoaded>
      ) : isLoading ? (
        <LoadingSpinner className={"absolute-center"} />
      ) : (
        <div className="px-16 mb-10 mt-10 grid grid-cols-2 gap-x-16 xl:px-10 xl:gap-x-8 lg:px-10 lg:gap-x-8 md:grid-cols-1 md:px-10 sm:px-10 sm:grid-cols-1">
          <div className="">
            <div className="flex gap-x-4 items-center lg:flex-col sm:flex-col sm:mb-2">
              <h1 className="text-2xl text-dark font-bold lg:mr-auto sm:mr-auto">
                {orderInfo.name} - #{orderInfo.order_id}
              </h1>
              <div className="lg:mr-auto sm:mr-auto bg-secondary text-primary text-base font-bold px-2 pt-1 uppercase h-fit ">
                {orderInfo.status}
              </div>
            </div>
            <p className="text-lg font-medium mt-1">
              {orderInfo.service_name
                ? orderInfo.service_name
                : "Serviciu Șters"}{" "}
              -{" "}
              {orderInfo.package_name ? orderInfo.package_name : "Pachet Șters"}
            </p>
            <div
              className={
                "regular-border h-fit font-bold text-center w-full relative" +
                (orderInfo.progress >= 50 ? " text-primary" : " text-secondary")
              }
            >
              <div
                className="absolute left-0 h-full bottom-0 bg-secondary"
                style={{ width: orderInfo.progress + "%" }}
              ></div>
              <div className="relative">{orderInfo.progress}%</div>
            </div>
            <div className="mt-4 flex gap-x-2 items-center">
              <h2 className=" text-xl font-semibold h-fit">
                Proiectul în formă scrisă
              </h2>
              {orderInfo.written_project_accepted ? (
                <div className="bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                  ACCEPTAT
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              {orderInfo.written_project ? (
                <div className="relative">
                  <div
                    className="!font-normal select-text h-[20rem] default-scroll p-2 regular-border overflow-y-auto"
                    dangerouslySetInnerHTML={{
                      __html: orderInfo.written_project.replaceAll(
                        "\n",
                        "<br/>"
                      ),
                    }}
                  ></div>
                  {orderInfo.written_project_modified_at &&
                  orderInfo.written_project_modified_at.length > 0 ? (
                    <div className="absolute bottom-0 right-1 text-dark opacity-75">
                      Modificat la: {orderInfo.written_project_modified_at}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="text-lg font-normal mt-1">
                  Adminul vă va scrie proiectul în curând
                </div>
              )}
              {orderInfo.written_project &&
              orderInfo.written_project_accepted === false ? (
                <button
                  onClick={WrittenProjectAcceptHandler}
                  className="regular-btn mt-1 flex ml-auto px-2"
                >
                  Acceptă proiectul scris
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="sm:mt-4 md:mt-4">
            <h2 className=" text-xl font-semibold h-fit">
              Poze/videoclipuri cu progresul proiectului
            </h2>
            <div className="flex flex-col !font-normal w-full max-h-[25rem] min-h-[25rem] default-scroll p-2 regular-border relative">
              <div className="grid grid-cols-3 gap-8 mt-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 overflow-y-auto default-scroll">
                {orderInfo.media &&
                  orderInfo.media.map((media, index) => {
                    return (
                      <div
                        key={index}
                        className="relative w-full group min-w-[10rem] "
                      >
                        <Link
                          target="_blank"
                          to={
                            "https://drive.google.com/uc?id=" + media.media_id
                          }
                        >
                          {media.type === "imagine" ? (
                            <img
                              src={
                                "https://drive.google.com/uc?id=" +
                                media.media_id
                              }
                              alt=""
                              className="regular-border opacity-90 transition-opacity ease-in-out duration-200 hover:opacity-100"
                            />
                          ) : (
                            <div className="relative">
                              <video
                                className="regular-border opacity-90 transition-opacity ease-in-out duration-200 hover:opacity-100"
                                src={
                                  "https://drive.google.com/uc?id=" +
                                  media.media_id
                                }
                              ></video>
                              <img
                                alt={"play-button"}
                                src={play_btn}
                                className="absolute-center w-8"
                              />
                            </div>
                          )}
                          <div className="text-right">
                            {DateTimeConvertor(media.created_at)}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
            {orderInfo.visual_project && orderInfo.visual_project.length > 0 ? (
              <div className="mt-4">
                <div className="flex gap-x-2 items-center">
                  <h2 className=" text-xl font-semibold">
                    Puteți accesa proiectul în formă vizuală
                  </h2>
                  {orderInfo.visual_project_accepted ? (
                    <div className="bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                      ACCEPTAT
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex gap-x-2 md:flex-col xl:flex-col lg:flex-col sm:flex-col">
                  <input
                    type="text"
                    className="regular-input grow"
                    value={orderInfo.visual_project}
                    readOnly
                  />
                  <span className="flex gap-x-2 justify-between xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 sm:flex-wrap sm:gap-y-2">
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(orderInfo.visual_project)
                      }
                      className="regular-btn sm:grow px-4"
                    >
                      Copiază linkul
                    </button>
                    {orderInfo.visual_project_accepted === false ? (
                      <button
                        onClick={VisualProjectAcceptHandler}
                        className="sm:grow px-4 regular-btn"
                      >
                        Acceptă proiectul vizual
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {orderInfo.project && orderInfo.project.length > 0 ? (
              <div className="mt-4">
                <div className="flex gap-x-2 items-center">
                  <h2 className=" text-xl font-semibold">
                    Puteți accesa proiectul finalizat
                  </h2>
                  {orderInfo.project_accepted ? (
                    <div className="bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                      ACCEPTAT
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex gap-x-2 md:flex-col xl:flex-col lg:flex-col sm:flex-col">
                  <input
                    type="text"
                    className="regular-input grow"
                    value={
                      "https://drive.google.com/uc?id=" + orderInfo.project
                    }
                    readOnly
                  />
                  <span className="flex gap-x-2 justify-between xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2 sm:flex-wrap sm:gap-y-2">
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(
                          "https://drive.google.com/uc?id=" + orderInfo.project
                        )
                      }
                      className="regular-btn sm:grow px-4"
                    >
                      Copiază linkul
                    </button>
                    {orderInfo.project_accepted === false ? (
                      <button
                        onClick={ProjectAcceptHandler}
                        className="sm:grow px-4 regular-btn"
                      >
                        Acceptă proiectul finalizat
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Order;
