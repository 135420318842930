import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../functions/constants.js";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageNotLoaded from "../../components/PageNotLoaded";
import { useSelector } from "react-redux";
import get_auth_config from "../../functions/GetAuthConfig.js";
function AdminServiceQuestions() {
  const admin = useSelector((state) => state.admin);
  const config = get_auth_config(admin.access);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const id = useParams().id;
  const [service, setService] = useState(null);
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "service/" + id)
      .then((response) => {
        setService(response.data);
        axios
          .get(api_url + "service/" + id + "/questions")
          .then((response) => {
            setQuestions(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setPageError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  function RemoveQuestionHandler(id) {
    axios
      .delete(api_url + "service/" + service.id + "/question/" + id, config)
      .then((response) => {
        setQuestions(questions.filter((question) => question.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function AddQuestionHandler(e) {
    e.preventDefault();
    const data = e.target;
    const question = {
      question: data.question.value,
      answer: data.answer.value,
    };
    if (question.question === "" || question.answer === "") {
      alert("Toate câmpurile sunt obligatorii");
      return;
    }
    axios
      .post(api_url + "service/" + service.id + "/question", question, config)
      .then((response) => {
        setQuestions([...questions, response.data]);
        data.reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div className="w-full px-16 mx-auto mt-8 xl:px-10 lg:px-10 md:px-10 sm:px-5 mb-10">
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>Admin | {service && service.name} | Întrebări frecvente</title>
      </Helmet>
      <h1 className="w-fit text-2xl">
        {service && service.name} | Listă întrebări frecvente
      </h1>
      <div className="flex justify-between mt-4 md:flex-col md:gap-y-8 sm:gap-y-8 sm:flex-col">
        <form
          className="w-[47%] md:w-full sm:w-full"
          onSubmit={AddQuestionHandler}
        >
          <input
            type="text"
            className="regular-border w-full sm:!min-w-[15rem]"
            placeholder="Întrebare"
            name="question"
          />
          <textarea
            maxLength={500}
            name="answer"
            placeholder="Răspuns"
            className="sm:!min-w-[15rem] mt-3 w-full h-40 text-base placeholder:text-lg default-scroll"
          ></textarea>
          <button className="regular-btn mt-1 flex ml-auto px-8 pt-1 sm:justify-center sm:w-full">
            Adaugă întrebare
          </button>
        </form>
        <div className="w-[47%] flex flex-col gap-y-4 md:w-full sm:w-full">
          {questions &&
            questions.map((question, index) => {
              return (
                <div
                  key={index}
                  className="select-text border-2 border-solid border-secondary p-4 relative"
                >
                  <button
                    onClick={(e) => RemoveQuestionHandler(question.id)}
                    className="text-2xl leading-0 absolute top-0 right-1"
                  >
                    x
                  </button>
                  <p className="w-fit text-lg font-semibold">
                    {question.question}
                  </p>
                  <p>{question.answer}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AdminServiceQuestions;
