import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../../functions/constants.js";
import { useParams } from "react-router-dom";
import GetGoogleDiveId from "../../functions/GetGoogleDriveId.js";
import { Link, useNavigate } from "react-router-dom";
import play_btn from "../../static/images/play-btn.svg";
import DateTimeConvertor from "../../functions/DateTimeConvertor.js";
import { useSelector } from "react-redux";
import get_auth_config from "../../functions/GetAuthConfig.js";
import { Helmet } from "react-helmet-async";
function AdminOrder() {
  const admin = useSelector((state) => state.admin);
  const id = useParams().id;
  const [orderInfo, setOrderInfo] = useState({});
  const [isWrittenProjectModified, setIsWrittenProjectModified] =
    useState(false);
  const navigate = useNavigate();
  const config = get_auth_config(admin.access);

  useEffect(() => {
    axios
      .get(api_url + "order/" + id, config)
      .then((response) => {
        const response_order = response.data;
        response_order.written_project_modified_at =
          response_order.written_project_modified_at
            ? DateTimeConvertor(response_order.written_project_modified_at)
            : null;
        setOrderInfo(response_order);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const order_statuses = [
    "Spre aprobare",
    "În pre-dezvoltare",
    "În dezvoltare",
    "Completat",
  ];

  function UpdateStatusHandler(e) {
    const status = e.target.value;
    axios
      .put(api_url + "order/" + id, { status: status }, config)
      .then((response) => {
        setOrderInfo({ ...orderInfo, status });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function ChangeProgressHandler(type) {
    if (type === "+") {
      if (orderInfo.progress < 100) {
        axios
          .put(
            api_url + "order/" + id,
            { progress: orderInfo.progress + 5 },
            config
          )
          .then((response) => {
            setOrderInfo({ ...orderInfo, progress: orderInfo.progress + 5 });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (orderInfo.progress > 0) {
        axios
          .put(
            api_url + "order/" + id,
            { progress: orderInfo.progress - 5 },
            config
          )
          .then((response) => {
            setOrderInfo({ ...orderInfo, progress: orderInfo.progress - 5 });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  function WrittenProjectModifiedHandler(e) {
    const written_project = e.target.value;
    if (written_project !== orderInfo.written_project) {
      setIsWrittenProjectModified(true);
    } else {
      setIsWrittenProjectModified(false);
    }
  }

  function WrittenProjectHandler(e) {
    const written_project = e.target.written_project.value;
    e.preventDefault();
    axios
      .put(
        api_url + "order/" + id,
        { written_project: written_project },
        config
      )
      .then((response) => {
        const written_project_modified_at = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        const written_project_accepted = false;
        setOrderInfo({
          ...orderInfo,
          written_project,
          written_project_modified_at,
          written_project_accepted,
        });
        setIsWrittenProjectModified(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function AddMediaHandler(e) {
    const type = e.target.type.value;
    const url = e.target.url.value;
    const media_id = GetGoogleDiveId(url);
    e.preventDefault();
    axios
      .post(api_url + "order/" + id + "/media", { type, media_id }, config)
      .then((response) => {
        const media = [...orderInfo.media, response.data];
        setOrderInfo({ ...orderInfo, media });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DeleteMediaHandler(id) {
    axios
      .delete(api_url + "order/" + id + "/media", config)
      .then((response) => {
        const media = orderInfo.media.filter((media) => media.id !== id);
        setOrderInfo({ ...orderInfo, media });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function SetProjectHandler(e) {
    e.preventDefault();
    const project_url = e.target.project_url.value;
    console.log(project_url);
    let project = " ";
    if (project_url === "https://drive.google.com/uc?id=" + orderInfo.project) {
      project = orderInfo.project;
    } else if (project_url.length > 0) {
      project = GetGoogleDiveId(project_url);
    }

    axios
      .put(api_url + "order/" + id, { project }, config)
      .then((response) => {
        setOrderInfo({ ...orderInfo, project });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function SetVisualProjectHandler(e) {
    const visual_project_url = e.target.visual_project_url.value;
    let visual_project = " ";
    if (visual_project_url.length > 0) {
      visual_project = visual_project_url;
    }
    e.preventDefault();
    axios
      .put(api_url + "order/" + id, { visual_project }, config)
      .then((response) => {
        setOrderInfo({ ...orderInfo, visual_project });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DeleteOrderHandler(e) {
    e.preventDefault();
    const confirmation = window.confirm(
      "Ești sigur că vrei să ștergi comanda " + orderInfo.name + "?"
    );
    if (confirmation)
      axios
        .delete(api_url + "order/" + id, config)
        .then((response) => {
          navigate("/admin");
        })
        .catch((error) => {});
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>{`Admin | Comanda ${orderInfo ? orderInfo.name : "..."}`}</title>
      </Helmet>
      <div className="px-16 mt-10 grid grid-cols-2 gap-x-16 xl:px-10 xl:gap-x-8 lg:gap-x-8 lg:px-10 md:grid-cols-1 md:px-10 sm:grid-cols-1 sm:px-10">
        <div>
          <div className="flex gap-x-4 items-center lg:flex-col sm:flex-col">
            <h1 className="text-2xl text-dark font-bold lg:mr-auto sm:mr-auto select-text">
              {orderInfo.name} - #{orderInfo.order_id}
            </h1>
            <select
              onChange={UpdateStatusHandler}
              defaultValue={orderInfo.status}
              value={orderInfo.status}
              className="lg:mr-auto sm:mr-auto bg-secondary block cursor-pointer appearance-none w-[12rem] max-w-[12rem] min-w-[12rem] text-primary text-base font-bold px-2 pt-1 uppercase h-fit "
            >
              {order_statuses.map((status, index) => {
                return (
                  <option
                    className="block w-fit cursor-pointer"
                    key={index}
                    value={status}
                  >
                    {status}
                  </option>
                );
              })}
            </select>
          </div>
          <p className="text-lg font-medium mt-1">
            {orderInfo.service_name ? orderInfo.service_name : "Serviciu Șters"}{" "}
            - {orderInfo.package_name ? orderInfo.package_name : "Pachet Șters"}
          </p>
          <div>
            <div className="flex gap-x-2 mt-1">
              <div
                className="text-xl h-fit cursor-pointer"
                onClick={(e) => ChangeProgressHandler("-")}
              >
                -
              </div>
              <div
                className={
                  "regular-border h-fit font-bold text-center w-full relative" +
                  (orderInfo.progress >= 50
                    ? " text-primary"
                    : " text-secondary")
                }
              >
                <div
                  className="absolute left-0 h-full bottom-0 bg-secondary"
                  style={{ width: orderInfo.progress + "%" }}
                ></div>
                <div className="relative">{orderInfo.progress}%</div>
              </div>
              <div
                className="text-xl h-fit cursor-pointer"
                onClick={(e) => ChangeProgressHandler("+")}
              >
                +
              </div>
            </div>
            <div className="mt-8 flex gap-x-2 items-center sm:flex-col sm:mb-2">
              <h2 className=" text-xl font-semibold h-fit sm:mr-auto">
                Proiectul în formă scrisă
              </h2>
              {orderInfo.written_project &&
              orderInfo.written_project.length > 0 ? (
                <div className="sm:mr-auto bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                  {orderInfo.written_project_accepted
                    ? "ACCEPTAT"
                    : "ÎN AȘTEPTARE"}
                </div>
              ) : (
                ""
              )}
            </div>
            <form onSubmit={WrittenProjectHandler}>
              <span className="relative">
                <textarea
                  name="written_project"
                  defaultValue={orderInfo.written_project}
                  onChange={WrittenProjectModifiedHandler}
                  className="sm:min-w-[15rem] !font-normal w-full max-h-[25rem] min-h-[25rem] default-scroll p-2 regular-border relative"
                ></textarea>
                {orderInfo.written_project_modified_at &&
                orderInfo.written_project_modified_at.length > 0 ? (
                  <div className="absolute bottom-1 right-1 text-dark opacity-75">
                    Modificat la: {orderInfo.written_project_modified_at}
                  </div>
                ) : (
                  ""
                )}
              </span>
              {isWrittenProjectModified ? (
                <button className="regular-btn flex ml-auto px-4 pt-1 sm:w-full sm:justify-center">
                  Trimite proiectul scris
                </button>
              ) : (
                ""
              )}
            </form>
          </div>
          <button
            onClick={DeleteOrderHandler}
            className={
              "sm:mt-2 sm:translate-y-0 regular-btn !bg-danger px-12 flex sm:w-full justify-center " +
              (isWrittenProjectModified ? " translate-y-[-100%]" : "")
            }
          >
            Șterge comanda
          </button>
        </div>
        <div className="md:mt-8 sm:mt-12">
          <h2 className=" text-xl font-semibold h-fit">
            Poze/videoclipuri cu progresul proiectului
          </h2>
          <div className="!font-normal flex-col flex w-full max-h-[25rem] min-h-[25rem] default-scroll p-2 regular-border relative">
            <form
              className="flex justify-between gap-x-1 lg:flex-col gap-y-2 sm:flex-col"
              onSubmit={AddMediaHandler}
            >
              <input
                name="url"
                type="text"
                className="w-[65%] grow lg:w-full sm:w-full sm:!min-w-[15rem]"
                placeholder="Link"
              />
              <span className="flex justify-between gap-x-1">
                <select
                  name="type"
                  className="grow block bg-primary max-w-[8rem] w-[8rem] min-w-[8rem] appearance-none"
                >
                  <option value="imagine" className="bg-primary">
                    Imagine
                  </option>
                  <option value="video" className="bg-primary">
                    Video
                  </option>
                </select>
                <button className="regular-btn px-4 sm:px-12">Adaugă</button>
              </span>
            </form>
            <div className="grid grid-cols-3 gap-8 mt-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 overflow-y-auto default-scroll">
              {orderInfo.media &&
                orderInfo.media.map((media, index) => {
                  return (
                    <div key={index} className="relative w-full group ">
                      <Link
                        target="_blank"
                        to={"https://drive.google.com/uc?id=" + media.media_id}
                      >
                        {media.type === "imagine" ? (
                          <img
                            src={
                              "https://drive.google.com/uc?id=" + media.media_id
                            }
                            alt=""
                            className="regular-border opacity-75 transition-opacity ease-in-out duration-200 hover:opacity-100"
                          />
                        ) : (
                          <div className="relative">
                            <video
                              className="regular-border opacity-75 transition-opacity ease-in-out duration-200 hover:opacity-100"
                              src={
                                "https://drive.google.com/uc?id=" +
                                media.media_id
                              }
                            ></video>
                            <img
                              alt={"play-button"}
                              src={play_btn}
                              className="absolute-center w-8"
                            />
                          </div>
                        )}
                      </Link>
                      <div
                        onClick={(e) => DeleteMediaHandler(media.id)}
                        className="z-10 group-hover:opacity-100 cursor-pointer ease-in-out duration-200 transition-opacity opacity-0 absolute text-primary bg-secondary px-2 top-0 right-0 text-xl"
                      >
                        x
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="mt-4 flex gap-x-2 items-center">
            <label className="text-xl font-semibold block">
              Fișier Proiect
            </label>
            {orderInfo.project && orderInfo.project.length > 0 ? (
              <div className="sm:mr-auto bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                {orderInfo.project_accepted ? "ACCEPTAT" : "ÎN AȘTEPTARE"}
              </div>
            ) : (
              ""
            )}
          </div>
          <form
            className="flex gap-x-4 sm:flex-col gap-y-2"
            onSubmit={SetProjectHandler}
          >
            <input
              name="project_url"
              defaultValue={
                orderInfo.project
                  ? "https://drive.google.com/uc?id=" + orderInfo.project
                  : ""
              }
              type="text"
              placeholder="Link Google Drive"
              className={
                "grow focus:opacity-100 transition-opacity duration-200 ease-in-out " +
                (orderInfo.project ? "opacity-50" : "")
              }
            />
            <button className="regular-btn px-4">Setează</button>
          </form>
          <div className="mt-4 flex gap-x-2 items-center">
            <label className="text-xl font-semibold block">
              Proiect Vizual
            </label>
            {orderInfo.visual_project && orderInfo.visual_project.length > 0 ? (
              <div className="sm:mr-auto bg-secondary text-primary text-sm font-bold px-2 pt-1 uppercase h-fit ">
                {orderInfo.visual_project_accepted
                  ? "ACCEPTAT"
                  : "ÎN AȘTEPTARE"}
              </div>
            ) : (
              ""
            )}
          </div>
          <form
            className="flex gap-x-4 sm:flex-col gap-y-2"
            onSubmit={SetVisualProjectHandler}
          >
            <input
              name="visual_project_url"
              defaultValue={
                orderInfo.visual_project ? orderInfo.visual_project : ""
              }
              type="text"
              placeholder="Link Proiect Vizual"
              className={
                "grow focus:opacity-100 transition-opacity duration-200 ease-in-out " +
                (orderInfo.visual_project ? "opacity-50" : "")
              }
            />
            <button className="regular-btn px-4">Setează</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminOrder;
