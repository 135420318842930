import { createSlice } from "@reduxjs/toolkit";

const initialState = "Admin Panel";

const AdminPageSlice = createSlice({
  name: "adminPage",
  initialState,
  reducers: {
    setPage(state, action) {
      return action.payload;
    },
  },
});

export const { setPage } = AdminPageSlice.actions;

export default AdminPageSlice.reducer;
