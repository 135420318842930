import React, {useState, useEffect} from "react";
import {motion} from 'framer-motion';
function ChangingQuote(){
    const [isAnimatingQuote, setIsAnimatingQuote] = useState(false);
    const [currentQuote, setCurrentQuote] = useState(0);
    const quotes = [
        {
          quote: 'If your business is not on the internet, then your business will be out of business',
          author: 'Bill Gates'
        },
        {
          quote: 'The internet is becoming the town square for the global village of tomorrow.',
          author: 'Bill Gates'
        },
        {
          quote: "The internet is the first thing that humanity has built that humanity doesn’t understand, the largest experiment in anarchy that we have ever had.",
          author: 'Eric Schmidt'
        },
        {
          quote: "Your website is the window to your business. Keep it fresh, keep it exciting, and keep it mobile-friendly.",
          author: "Jay Conrad Levinson"
        },
        {
          quote: "The business of the future is built on code.",
          author: "Vivek Wadhwa"
        },
        {
          quote: "Technology is best when it brings people together.",
          author: "Matt Mullenweg"
        },
        {
          quote:"Design is not just what it looks like and feels like. Design is how it works.",
          author:"Steve Jobs"
        },
        {
          quote: "The best way to succeed in business is to innovate. Embrace new technologies and stay ahead of the curve.",
          author: "Reid Hoffman"
        }
      ]

      useEffect(() => {

        const animation = setTimeout(() => {
          setIsAnimatingQuote(true);
        }, 4000);
    
        const interval = setInterval(() => {
          setCurrentQuote((currentQuote) => {
            if(currentQuote + 1 >= quotes.length){
              return 0;
            }
            return currentQuote + 1;
          })
        }, 5000);
        return () => {
          clearInterval(interval)
          clearTimeout(animation);
          setIsAnimatingQuote(false);
        };
      }, [currentQuote, quotes.length]);

    return (
        <motion.p className='text-xl w-fit text-center mx-auto font-semibold text-primary mt-4' initial={{opacity: 0}} animate={{opacity: (isAnimatingQuote ? 0 : 1)}} 
        transition={{duration: 0.5}}>{"\""+quotes[currentQuote].quote + "\" - " + quotes[currentQuote].author}</motion.p>
    )
}

export default ChangingQuote;