import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    email: null,
    code: null,
}

const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder(state, action) {
            return {
                email: action.payload.email,
                code: action.payload.code
            }
        },
        clearOrder(state, action) {
            return null
        }
    }
})

export const { setOrder, clearOrder } = OrderSlice.actions

export default OrderSlice.reducer