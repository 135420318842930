import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const PackageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        setPackageId(state, action) {
            return action.payload
        },
        clearPackageId(state, action) {
            return null
        }
    }
})

export const { setPackageId, clearPackageId } = PackageSlice.actions

export default PackageSlice.reducer