import React from "react";
import plus from "../../static/images/plus.svg";
import axios from "axios";
import { api_url } from "../../functions/constants";
import { useNavigate } from "react-router-dom";
import AdminPackageCard from "../../components/AdminPackageCard";
import AddPackageQuestionsForm from "../../components/AddPackageQuestionsForm.js";
import AddPackageQuestionCard from "../../components/AddPackageQuestionCard.js";
import { useSelector } from "react-redux";
function AdminAddService() {
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);

  const [guarantees, setGuarantees] = React.useState([]);
  const [serviceIcon, setServiceIcon] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [packageIcon, setPackageIcon] = React.useState(null);
  const [packages, setPackages] = React.useState([]);

  const [serviceName, setServiceName] = React.useState("");
  const [serviceDescription, setServiceDescription] = React.useState("");
  const [servicePrice, setServicePrice] = React.useState("");
  const [discountPrice, setDiscountPrice] = React.useState(0);

  const [packageQuestions, setPackageQuestions] = React.useState([]);

  function AddGuaranteeHandler(e) {
    e.preventDefault();
    const guarantee = e.target.guarantee.value;
    if (guarantees.includes(guarantee)) {
      alert("Pachetul deja există");
      return;
    }
    e.target.reset();
    const new_guarantees = [...guarantees, guarantee];
    setGuarantees(new_guarantees);
  }

  function RemoveGuaranteeHandler(e, key) {
    e.preventDefault();
    const new_guarantees = guarantees.filter((guarantee_name, index) => {
      return index !== key;
    });
    setGuarantees(new_guarantees);
  }

  function AddIconHandler(e) {
    e.preventDefault();
    const icon = e.target.files[0];
    const type = icon.type;
    console.log(icon);
    if (!type.includes("svg")) {
      alert("Formatul imaginii trebuie să fie SVG");
      return;
    }
    setServiceIcon(icon);
  }

  function AddQuestionHandler(e) {
    e.preventDefault();
    const question = e.target.question.value;
    const answer = e.target.answer.value;
    const new_question = {
      question: question,
      answer: answer,
    };
    if (questions.includes(new_question)) {
      alert("Întrebarea deja există");
      return;
    }

    document.getElementById("add_question_form").reset();
    const new_questions = [...questions, new_question];
    setQuestions(new_questions);
  }

  function RemoveQuestionHandler(e, key) {
    e.preventDefault();
    const new_questions = questions.filter((question, index) => {
      return index !== key;
    });
    setQuestions(new_questions);
  }

  function AddPackageIconHandler(e) {
    e.preventDefault();
    const icon = e.target.files[0];
    const type = icon.type;
    if (!type.includes("svg")) {
      alert("Formatul imaginii trebuie să fie SVG");
      return;
    }
    setPackageIcon(icon);
  }

  function AddPackageHandler(e) {
    e.preventDefault();
    const package_name = e.target.package_name.value;
    const package_description = e.target.package_description.value;
    const package_icon = packageIcon;
    const package_button_message = e.target.button_message.value;

    if (package_icon === null) {
      alert("Nu ați selectat o imagine");
      return;
    }

    if (package_name === "") {
      alert("Nu ați completat numele pachetului");
      return;
    }

    if (package_description === "") {
      alert("Nu ați completat descrierea pachetului");
      return;
    }

    if (package_button_message === "") {
      alert("Nu ați completat mesajul butonului");
      return;
    }

    if (packageQuestions.length === 0) {
      alert("Nu ați adăugat întrebări");
      return;
    }

    const new_package = {
      name: package_name,
      description: package_description,
      icon: package_icon,
      button_message: package_button_message,
      questions: packageQuestions,
    };

    document.getElementById("package_question_form").reset();

    const package_names = packages.map((list_package) => {
      return list_package.package_name;
    });
    if (package_names.includes(package_name)) {
      alert("Pachetul deja există");
      return;
    }

    e.target.reset();
    setPackageIcon(null);
    setPackageQuestions([]);

    const new_packages = [...packages, new_package];
    setPackages(new_packages);
  }

  function RemovePackageHandler(e, key) {
    e.preventDefault();
    const new_packages = packages.filter((package_name, index) => {
      return index !== key;
    });
    setPackages(new_packages);
  }

  function SubmitServiceHandler() {
    const name = serviceName;
    const description = serviceDescription;
    const starting_price = servicePrice;
    let discount_price = discountPrice;
    if (discount_price === "") {
      discount_price = 0;
    }
    const icon = serviceIcon;

    if (
      icon === null ||
      name === "" ||
      description === "" ||
      starting_price === "" ||
      discount_price === ""
    ) {
      alert("Nu ați completat toate câmpurile de bază ale serviciului");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("starting_price", starting_price);
    formData.append("discount_price", discount_price);
    formData.append("icon", icon);

    formData.append("guarantees", JSON.stringify(guarantees));
    formData.append("questions", JSON.stringify(questions));

    formData.append("packages", JSON.stringify(packages));
    packages.forEach((package_item, index) => {
      formData.append("package_icon_" + index, package_item.icon);
    });
    const token = admin.access;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: token,
      },
    };
    axios
      .post(api_url + "service", formData, config)
      .then((response) => {
        if (response.status === 201) {
          navigate("/admin");
        }
      })
      .catch((error) => {
        alert("A apărut o eroare");
      });
  }

  function AddPackageQuestionsHandler(question) {
    const package_questions = packageQuestions.map(
      (package_question) => package_question.question
    );
    if (package_questions.includes(question)) {
      alert("Întrebarea deja există");
      return;
    }
    setPackageQuestions([...packageQuestions, question]);
  }

  function RemovePackageQuestionHandler(key) {
    const new_package_questions = packageQuestions.filter(
      (package_question, index) => {
        return index !== key;
      }
    );
    setPackageQuestions(new_package_questions);
  }

  return (
    <div className="w-full px-16 mx-auto mt-8 xl:px-10 lg:px-10 md:px-10 sm:px-5 mb-10">
      {/* Headerul și butonul de adăugare serviciu */}

      <div className="flex justify-between sm:flex-col">
        <h1 className="w-fit">Adăugare serviciu nou</h1>
        <button
          className="regular-btn px-6 pt-1"
          onClick={SubmitServiceHandler}
        >
          Adaugă serviciul
        </button>
      </div>
      <p className="text-xl">
        Completează următorul formular pentru adăugarea unui nou serviciu pe
        platformă
      </p>

      <div className="flex mt-4 md:flex-col sm:flex-col">
        <div className="w-1/2 md:w-full sm:w-full md:pr-0 sm:pr-0 pr-16 lg:pr-8 md:border-r-0 sm:border-r-0 border-r-2 border-r-dark">
          {/* Formularul de informații de bază despre serviciu (nume, preț, descriere)*/}
          <div className="flex justify-between lg:flex-col sm:flex-col gap-y-4">
            <div className="w-[47%] lg:w-full sm:w-full">
              <input
                type="text"
                placeholder="Nume serviciu"
                className="w-full !min-w-[15rem]"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
              />
              <input
                type="file"
                className="hidden"
                id="icon"
                onChange={AddIconHandler}
              />
              <div className="flex justify-between mt-2">
                <label htmlFor="icon" className="cursor-pointer w-fit block">
                  <div className="h-40 w-40 regular-border relative">
                    <img
                      alt={"service-icon"}
                      src={
                        serviceIcon == null
                          ? plus
                          : URL.createObjectURL(serviceIcon)
                      }
                      className={
                        "top-1/2 absolute-center !fill-white" +
                        (serviceIcon == null
                          ? " w-1/3"
                          : " w-2/3 !fill-white text-white")
                      }
                    />
                  </div>
                </label>
                <div className="flex flex-col gap-y-2">
                  <input
                    value={servicePrice}
                    onChange={(e) => setServicePrice(e.target.value)}
                    type="number"
                    placeholder="Preț de start €"
                    className="ml-2 block h-fit !min-w-0"
                  />
                  <input
                    value={discountPrice}
                    onChange={(e) => setDiscountPrice(e.target.value)}
                    type="number"
                    placeholder="Preț de discount €"
                    className="ml-2 block h-fit !min-w-0"
                  />
                </div>
              </div>
            </div>

            {/* Formularul de adăugare a garanțiilor serviciului */}
            <form
              className="w-[47%] lg:w-full sm:w-full"
              onSubmit={AddGuaranteeHandler}
              id="add_guarantees_form"
            >
              <div className=" relative">
                <input
                  type="text"
                  placeholder="Garanție serviciu"
                  className="!w-full !min-w-[15rem]"
                  name="guarantee"
                />
                <button className="regular-btn absolute h-full px-4 right-0">
                  Adaugă
                </button>
              </div>

              {/* Lista de garanții */}
              <div className="mt-2 regular-border h-40">
                <ul className="h-full overflow-y-auto default-scroll">
                  {guarantees.map((guarantee, index) => {
                    return (
                      <li
                        key={index}
                        className="select-text flex justify-between items-center h-5 mt-2 px-4"
                      >
                        <p className="truncate">{guarantee}</p>
                        <button
                          onClick={(e) => RemoveGuaranteeHandler(e, index)}
                          className="text-2xl leading-0"
                        >
                          x
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </form>
          </div>

          {/* Adăugarea descrierii serviciului */}
          <div className="mt-4">
            <textarea
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
              placeholder="Descriere serviciu"
              className="text-base w-full h-40 default-scroll"
            ></textarea>
          </div>

          {/* Formular de adăugare a întrebărilor frecvente */}
          <p className="text-xl mt-4">Întrebări frecvente</p>
          <form onSubmit={AddQuestionHandler} id="add_question_form">
            <input
              type="text"
              placeholder="Întrebare"
              className="w-full mt-1 font-medium"
              name="question"
            />
            <textarea
              placeholder="Răspuns"
              className="text-base w-full h-40 mt-2 default-scroll"
              name="answer"
            ></textarea>
            <button className="regular-btn px-8 pt-1 ml-auto flex sm:w-full sm:justify-center">
              Adaugă Întrebare
            </button>
          </form>

          {/* Lista de întrebări frecvente */}
          <div className="flex flex-col gap-y-4 mt-4 mb-4">
            {questions.map((question, index) => {
              return (
                <div
                  key={index}
                  className="select-text relative regular-border p-4"
                >
                  <button
                    onClick={(e) => RemoveQuestionHandler(e, index)}
                    className="text-2xl leading-0 absolute top-0 right-1"
                  >
                    x
                  </button>
                  <p className="text-lg font-semibold mb-1">
                    {question.question}
                  </p>
                  <p className="font-normal">{question.answer}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Formular de adăugare a pachetelor */}
        <div className="w-1/2 pl-16 lg:pl-8 md:pl-0 sm:pl-0 sm:w-full md:w-full">
          <p className="text-xl">Adăugare pachet</p>
          <form
            className="flex justify-between w-full sm:flex-col gap-y-2"
            onSubmit={AddPackageHandler}
            id="add_package_form"
          >
            <div className="w-[47%] sm:w-full">
              <input
                type="text"
                name="package_name"
                placeholder="Nume pachet"
                className="w-full !min-w-[15rem]"
              />
              <input
                type="file"
                className="hidden"
                id="packageIcon"
                onChange={AddPackageIconHandler}
              />
              <div className="flex justify-between mt-2">
                <label
                  htmlFor="packageIcon"
                  className="cursor-pointer w-fit block"
                >
                  <div className="h-40 w-40 regular-border relative">
                    <img
                      alt={"package-icon"}
                      src={
                        packageIcon == null
                          ? plus
                          : URL.createObjectURL(packageIcon)
                      }
                      className={
                        "top-1/2 absolute-center !fill-white" +
                        (packageIcon == null
                          ? " w-1/3"
                          : " w-2/3 !fill-white text-white")
                      }
                    />
                  </div>
                </label>
                <input
                  type="text"
                  name="button_message"
                  placeholder="Mesaj buton"
                  className="ml-2 block h-fit !min-w-0"
                />
              </div>
            </div>
            <div className="w-[47%] sm:w-full">
              <textarea
                maxLength={500}
                name="package_description"
                placeholder="Descriere pachet"
                className="!min-w-[15rem] w-full h-40 text-base placeholder:text-lg default-scroll"
              ></textarea>
              <button className="regular-btn w-full pt-1">Adaugă Pachet</button>
            </div>
          </form>

          <AddPackageQuestionsForm AddHandler={AddPackageQuestionsHandler} />
          <div className="flex flex-col gap-y-2 mt-4 mb-4">
            {packageQuestions.map((question, index) => {
              return (
                <AddPackageQuestionCard
                  removeHandler={RemovePackageQuestionHandler}
                  question={question}
                  id={index}
                  key={index}
                />
              );
            })}
          </div>

          {/* Lista pachetelor */}
          <div className="mt-4 flex flex-col gap-y-4">
            {packages.map((list_package, index) => {
              return (
                <AdminPackageCard
                  button_message={list_package.button_message}
                  questions={list_package.questions}
                  id={index}
                  name={list_package.name}
                  removeHandler={RemovePackageHandler}
                  icon={URL.createObjectURL(list_package.icon)}
                  description={list_package.description}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAddService;
