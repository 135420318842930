import React, { useState, useEffect } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import box from "../static/images/box.svg";
import arrow from "../static/images/arrow.svg";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { history_urls } from "../functions/constants.js";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { api_url, media_url } from "../functions/constants.js";
const HistoryProjects = () => {
  const [boxClicked, setBoxClicked] = useState(false);
  const [historyUrlsIndex, setHistoryUrlsIndex] = useState(0);
  const [videos, setVideos] = useState([]);

  const isMD = useMediaQuery("(max-width: 1048px)");
  const isSM = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    axios.get(api_url + "home/videos").then((response) => {
      setVideos(response.data);
    });

    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message.includes("The play() request")
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const x = useMotionValue(-8);
  const y = useTransform(x, [-8, 0, 8], [0, -6, 0]);

  const [xState, setXState] = useState("left");
  useEffect(() => {
    const animation_timeout = setTimeout(() => {
      if (xState === "left") {
        setXState("right");
      } else {
        setXState("left");
      }
    }, 400);
    return () => clearTimeout(animation_timeout);
  }, [xState]);

  // useEffect(() => {
  //   const video = document.getElementById("history_video");

  //   let video_dissapear_timeout = null;
  //   let video_on_load_event = null;
  //   let verify_if_video_is_playing_timeout = null;
  //   if (boxClicked) {
  //     video.load();
  //     video_on_load_event = video.addEventListener("canplay", () => {
  //       var isPlaying =
  //         video.currentTime > 0 &&
  //         !video.paused &&
  //         !video.ended &&
  //         video.readyState > video.HAVE_CURRENT_DATA;
  //       if (!isPlaying) video.play();
  //     });
  //   } else {
  //     video.pause();

  //     video_dissapear_timeout = setTimeout(() => {
  //       if (historyUrlsIndex === videos.length - 1) {
  //         setHistoryUrlsIndex(0);
  //       } else {
  //         setHistoryUrlsIndex(historyUrlsIndex + 1);
  //       }
  //     }, 300);
  //   }
  //   return () => {
  //     clearTimeout(video_dissapear_timeout);
  //     clearTimeout(verify_if_video_is_playing_timeout);
  //     video.removeEventListener("loadeddata", video_on_load_event);
  //   };
  // }, [boxClicked]);

  useEffect(() => {
    if (!boxClicked) {
      console.log("video dissapear");
      const video_dissapear_timeout = setTimeout(() => {
        if (historyUrlsIndex === videos.length - 1) {
          setHistoryUrlsIndex(0);
          console.log(videos[0]);
        } else {
          setHistoryUrlsIndex(historyUrlsIndex + 1);
          console.log(videos[historyUrlsIndex + 1]);
        }
      }, 400);
      return () => clearTimeout(video_dissapear_timeout);
    }
  }, [boxClicked]);

  return (
    <div
      className={
        "bg-tertiary px-36 pt-12 lg:px-8 xl:px-16 md:px-8 sm:px-8 flex flex-col relative transition-all ease-in-out duration-300 min-h-[50rem] sm:min-h-[45rem] sm:!h-[50vh] " +
        (boxClicked ? "h-[85vh]" : "h-[75vh] ")
      }
    >
      <h2 className="text-center text-2xl font-semibold text-primary">
        Oferim produse care vă fac să ieșiți în evidență
      </h2>
      <p className="text-center text-lg font-normal text-primary">
        {isSM
          ? "Fiecare produs este personalizat pentru a se potrivi nevoilor tale."
          : "Fiecare produs este personalizat pentru a se potrivi nevoilor tale, iar designul este creat pentru a se potrivi cu identitatea ta de brand."}
      </p>
      <div
        className={
          "grid grid-cols-3 w-1/5 xl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 min-w-[20rem] md:translate-y-[75%] sm:translate-y-[100%] top-1/2 translate-y-[50%] absolute left-1/2 translate-x-[-50%] transition-opacity ease-in-out duration-300 " +
          (boxClicked ? "opacity-0" : "opacity-100 delay-700")
        }
      >
        <motion.img
          initial={{ y: -25, rotate: "80deg" }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "reverse",
          }}
          animate={{ x: 0, y: 0 }}
          src={arrow}
          className="rotate-[80deg] translate-x-[3rem]"
        />
        <motion.img
          initial={{ y: -25, rotate: "90deg" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "reverse",
          }}
          animate={{ x: 0, y: 0 }}
          src={arrow}
          className="rotate-90"
        />
        <motion.img
          initial={{ y: -25, rotate: "100deg" }}
          transition={{
            delay: 0.2,
            duration: 0.5,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "reverse",
          }}
          animate={{ x: 0, y: 0 }}
          src={arrow}
          className="rotate-[100deg] translate-x-[-3rem] "
        />
      </div>
      <span className="relative h-full">
        <div
          className={
            "absolute-center transition-opacity ease-in-out duration-200 " +
            (!boxClicked ? "opacity-0 duration-0" : "delay-500 !opacity-100")
          }
        >
          <LoadingSpinner color={"primary"} />
        </div>

        <motion.div
          id="history_video"
          className="border-primary border-solid border-4 h-fit absolute  group"
          initial={{
            opacity: 0,
            bottom: 0,
            width:
              videos[historyUrlsIndex]?.video_type == "desktop"
                ? "15rem"
                : "5rem",
            left: "50%",
            translateX: "-50%",
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            stiffness: 1000,
            damping: 1000,
          }}
          animate={
            boxClicked
              ? {
                  bottom:
                    (videos[historyUrlsIndex]?.video_type == "desktop" &&
                      isSM &&
                      "25%") ||
                    "10%",
                  opacity: 1,
                  width: isSM
                    ? videos[historyUrlsIndex]?.video_type == "desktop"
                      ? "22rem"
                      : isMD
                      ? "30rem"
                      : "35%"
                    : videos[historyUrlsIndex]?.video_type == "desktop"
                    ? "50rem"
                    : "13rem",
                  paddingBottom: isSM ? "12rem" : isMD ? "18" : "28rem",
                  transition: {
                    width: { delay: 0.1 },
                    paddingBottom: { delay: 0.4 },
                  },
                }
              : {
                  bottom: 0,
                  width:
                    videos[historyUrlsIndex]?.video_type == "desktop"
                      ? "3rem"
                      : "3rem",
                  opacity: 0,
                  transition: {
                    opacity: { delay: 0.6 },
                    bottom: { delay: 0.6 },
                    width: { delay: 0.5 },
                  },
                }
          }
        >
          <iframe
            src={
              videos.length === 0
                ? "https://drive.google.com/file/d/1fruNdl2sEfCPsGzRL0PKsYHod5y5_wfu/preview"
                : `https://drive.google.com/file/d/${videos[historyUrlsIndex]?.video}/preview`
            }
            className="w-full h-full absolute top-0 left-0 bottom-0 right-0 object-cover"
            // style="width:100%;height:100%;position:absolute;left:0px;top:0px;"
            frameborder="0"
            width="100%"
            height="100%"
            allowfullscreen="true"
            allow="autoplay"
          ></iframe>
        </motion.div>
      </span>
      <motion.img
        style={boxClicked ? {} : { y, x }}
        initial={{ x: -10 }}
        whileHover={{ scale: 1.05, x: 0, y: 0 }}
        animate={
          boxClicked
            ? { width: isSM ? "15rem" : "20rem", x: 0, y: 0.1 }
            : { x: xState === "left" ? -8 : 8 }
        }
        transition={{ duration: 0.4, ease: "easeInOut" }}
        onClick={() => setBoxClicked(!boxClicked)}
        className="mx-auto w-96 mt-auto cursor-pointer"
        src={box}
      />
      <motion.img
        initial={{ x: -20, rotate: "180deg" }}
        transition={{
          delay: 0.2,
          duration: 0.4,
          repeat: Infinity,
          ease: "easeInOut",
          repeatType: "reverse",
        }}
        animate={{ x: 0, y: 0 }}
        src={arrow}
        className={
          "w-[7rem] bottom-[10%] right-[30%] xl:right-[27%] lg:right-[22%] md:right-[15%] sm:hidden absolute opacity-0 transition-opacity duration-200 ease-in-out " +
          (boxClicked ? "opacity-100 delay-700" : "")
        }
      />
    </div>
  );
};

export default HistoryProjects;
