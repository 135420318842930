import React from "react";
import { motion } from "framer-motion";
import { RotatingSquare } from "react-loader-spinner";
function LoadingSpinner({ className, color = "secondary" }) {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
    >
      <RotatingSquare
        height="100"
        width="100"
        color={color == "secondary" ? "#432534" : "#EFD6AC"}
        ariaLabel="rotating-square-loading"
        strokeWidth="4"
        wrapperStyle={{}}
        wrapperClass={className}
        visible={true}
      />
    </motion.div>
  );
}

export default LoadingSpinner;
