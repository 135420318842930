import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const ErrorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state, action) {
            return action.payload
        },
        clearError(state, action) {
            return null
        }
    }
})

export const { setError, clearError } = ErrorSlice.actions

export default ErrorSlice.reducer
