import React, { useEffect, useState } from "react";
import ScriptifyAdminLogo from "../../static/images/scriptify-admin.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url, auth_url } from "../../functions/constants.js";
import { useDispatch } from "react-redux";
import { setAdmin } from "../../store/AdminSlice";
import ReCAPTCHA from "react-google-recaptcha";
function AdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  function onChange(value) {
    if (value) setIsRecaptchaVerified(true);
    else setIsRecaptchaVerified(false);
  }

  function SubmitForm(e) {
    e.preventDefault();
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (!isRecaptchaVerified) {
      alert("Verifică că nu ești robot");
      return;
    }

    axios
      .post(`${auth_url}jwt/create/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const refresh = response.data.refresh;
        const access = response.data.access;
        console.log(refresh, access);
        dispatch(setAdmin({ refresh, access }));
        navigate("/admin");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <img
        src={ScriptifyAdminLogo}
        alt="Scriptify Admin Logo"
        className={"mx-auto mt-4 w-[30rem]"}
      />
      <form
        className={"flex flex-col absolute-center top-1/3 gap-y-4"}
        onSubmit={SubmitForm}
      >
        <input type="email" placeholder={"Email"} name="email" />
        <input type="password" placeholder={"Cod de acces"} name="password" />
        <button className="regular-btn">Autentifică-mă</button>
        <ReCAPTCHA
          sitekey="6LfvneomAAAAAI9pKz_RstfdHFANjs0pLeLdNgxX"
          onChange={onChange}
        />
      </form>
    </div>
  );
}

export default AdminLogin;
