import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: localStorage.getItem("refresh"),
  access: localStorage.getItem("access"),
};

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin(state, action) {
      localStorage.setItem("refresh", action.payload.refresh);
      localStorage.setItem("access", action.payload.access);
      return {
        refresh: action.payload.refresh,
        access: action.payload.access,
      };
    },
  },
});

export const { setAdmin } = AdminSlice.actions;

export default AdminSlice.reducer;
