import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { api_url, media_url } from "../../functions/constants";
import change from "../../static/images/change.svg";
import plus from "../../static/images/plus.svg";
import ImageCropModal from "../../components/ImageCropModal";
import { useSelector, useDispatch } from "react-redux";
import { setPage } from "../../store/AdminPageSlice.js";
import { setError } from "../../store/ErrorSlice.js";
import { setNotification } from "../../store/NotificationSlice.js";
const AdminPortofolioCategory = () => {
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }
  useEffect(() => {}, [name]);

  useEffect(() => {
    axios.get(api_url + "portofolio/category/" + id).then((res) => {
      setCategory(res.data);
      setName(res.data.name);
      setDescription(res.data.description);
      dispatch(setPage("Categoria " + res.data.name));
    });
  }, []);

  function SubmitPortofolioCategoryChanges() {
    if (name === "" || description === "") {
      dispatch(setError("Toate câmpurile sunt obligatorii!"));
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (resultFile) {
      formData.append("icon", resultFile);
    }
    const token = admin.access;

    axios
      .put(api_url + "portofolio/category/" + id, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch(setNotification("Categoria a fost modificată cu succes!"));
        navigate("/admin/portofolio");
      })
      .catch((err) => {
        dispatch(setError("A aparut o eroare la modificarea categoriei!"));
        console.log(err);
      });
  }

  return (
    <div className="px-20 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-2">
      <div className="flex justify-between sm:flex-col">
        <h1 className="w-fit sm:text-2xl">Modificare categorie</h1>
        <button
          className="regular-btn px-6 pt-1"
          onClick={SubmitPortofolioCategoryChanges}
        >
          Aplică modificările
        </button>
      </div>
      <p className="text-xl sm:mt-2">
        Editează următorul formular pentru modificarea categoriei în portofoliu.
      </p>
      <div className="flex gap-x-4 mt-2 sm:flex-col gap-y-2">
        <form className="w-1/4 md:w-1/2 sm:w-full">
          <div className="flex flex-col gap-y-2">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nume categorie"
              className="block h-fit !min-w-0"
              type="text"
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              placeholder="Descriere"
              className="block h-fit !min-w-0 min-h-[10.25rem]"
            />
          </div>
        </form>
        {resultImage ? (
          <label
            htmlFor="product-graphics"
            className="w-52 block relative text-white h-52 group cursor-pointer border-2 border-solid border-secondary"
          >
            <img src={resultImage} className="w-full" />
            <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
            <img
              src={change}
              className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
            />
            <input
              ref={file}
              onChange={selectFile}
              id="product-graphics"
              type="file"
              className="hidden"
            />
          </label>
        ) : (
          <label className="w-52 block relative h-52 border-2 border-solid border-secondary group cursor-pointer">
            <img src={media_url + category.icon} className="w-full" />
            <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
            <img
              src={change}
              className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
            />
            <input
              ref={file}
              onChange={selectFile}
              type="file"
              className="hidden"
            />
          </label>
        )}
      </div>
      <ImageCropModal
        aspectRatio={1 / 1}
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </div>
  );
};

export default AdminPortofolioCategory;
