import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../store/ErrorSlice";
function ErrorMessage() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);
  const [show, setShow] = useState(false);
  useEffect(() => {
    let show_error_timeout;
    if (error) {
      setShow(true);
      show_error_timeout = setTimeout(() => {
        setShow(false);
      }, 4000);
    }
    return () => {
      clearTimeout(show_error_timeout);
    };
  }, [error]);

  useEffect(() => {
    let clear_error_timeout;
    if (!show) {
      clear_error_timeout = setTimeout(() => {
        dispatch(clearError());
      }, 500);
    }
    return () => {
      clearTimeout(clear_error_timeout);
    };
  }, [show, dispatch]);

  return (
    <motion.div
      initial={{ translateX: "100%" }}
      animate={{ translateX: show ? "-5%" : "100%" }}
      transition={{
        duration: 0.1,
        ease: "easeInOut",
        type: "spring",
        bounce: 0.25,
        stiffness: 50,
      }}
      className="border-danger bg-primary z-40 text-danger fixed right-0 bottom-8 regular-border py-2 px-8"
    >
      Eroare: {error ? error : ""}
    </motion.div>
  );
}
export default ErrorMessage;
