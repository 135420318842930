import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../../functions/constants";
import { useSelector, useDispatch } from "react-redux";
import { setPage } from "../../store/AdminPageSlice.js";
import { setError } from "../../store/ErrorSlice.js";
import { setNotification } from "../../store/NotificationSlice.js";
import { useNavigate, useParams } from "react-router-dom";
import x from "../../static/images/x.svg";
import GetGoogleDiveId from "../../functions/GetGoogleDriveId.js";
import { technologies } from "../../functions/constants";
import ImageCropModal from "../../components/ImageCropModal";

const AdminPortofolioProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [product, setProduct] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [url, setUrl] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);
  const admin = useSelector((state) => state.admin);
  const [newVideo, setNewVideo] = useState("");
  const [newFeature, setNewFeature] = useState("");
  const [addedTechnologies, setAddedTechnologies] = useState([]);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }
  useEffect(() => {
    if (resultImage) {
      setImages([...images, { file: resultFile, image: resultImage }]);

      setResultImage(null);
      setResultFile(null);
    }
  }, [resultImage]);
  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  useEffect(() => {
    axios
      .get(api_url + "portofolio/item/" + id)
      .then((res) => {
        setProduct(res.data);
        setName(res.data.name);
        setDescription(res.data.description);
        setCategory(res.data.category.id);
        setUrl(res.data.url);
        dispatch(
          setPage(
            "Produsul " +
              res.data.name +
              " din categoria " +
              res.data.category.name
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(api_url + "portofolio/categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function AddFeature(e) {
    e.preventDefault();
    if (features.includes(newFeature)) {
      return;
    }
    setFeatures([...features, newFeature]);
    setNewFeature("");
  }

  function AddVideo(e) {
    e.preventDefault();
    console.log(newVideo);
    const newVideos = [...videos, GetGoogleDiveId(newVideo)];
    setVideos(newVideos);
    setNewVideo("");
  }

  function AddTechnology(e) {
    e.preventDefault();
    const new_tehnology = e.target.technology.value;
    if (addedTechnologies.includes(new_tehnology)) {
      return;
    }
    setAddedTechnologies([
      ...addedTechnologies,
      technologies.find((technology) => technology.name == new_tehnology),
    ]);
  }

  function SubmitPortofolioItemChanges() {
    if (
      name == "" ||
      description == "" ||
      category == "" ||
      url == "" ||
      (images.length === 0 && product.images.length === 0) ||
      (features.length === 0 && product.features.length === 0)
    ) {
      dispatch(setError("Toate câmpurile sunt obligatorii"));
      return;
    }

    let submitted_videos = 0;
    let submitted_images = 0;
    let submitted_features = 0;
    let submitted_technologies = 0;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("url", url);

    const token = admin.access;

    axios
      .put(api_url + "portofolio/item/" + id, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const new_product = response.data;
        const new_product_images = new_product.images.map((image) => image.id);
        const new_product_videos = new_product.videos.map((video) => video.id);
        const new_product_features = new_product.features.map(
          (feature) => feature.id
        );
        const new_product_technologies = new_product.technologies.map(
          (technology) => technology.id
        );
        const old_product_images = product.images.map((image) => image.id);
        const old_product_videos = product.videos.map((video) => video.id);
        const old_product_features = product.features.map(
          (feature) => feature.id
        );
        const old_product_technologies = product.technologies.map(
          (technology) => technology.id
        );
        if (
          new_product_images.length == product.images.length &&
          new_product_videos.length == product.videos.length &&
          new_product_features.length == product.features.length &&
          new_product_technologies.length == product.technologies.length
        ) {
          CheckSubmitted(
            submitted_images,
            submitted_videos,
            submitted_features,
            submitted_technologies
          );
        }

        new_product_images.forEach((image) => {
          if (!old_product_images.includes(image)) {
            axios
              .delete(
                api_url + "portofolio/item/" + product.id + "/image/" + image,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                console.log("added success");
                CheckSubmitted(
                  submitted_images,
                  submitted_videos,
                  submitted_features,
                  submitted_technologies
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
        new_product_videos.forEach((video) => {
          if (!old_product_videos.includes(video)) {
            axios
              .delete(
                api_url + "portofolio/item/" + product.id + "/video/" + video,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                console.log("added success");
                CheckSubmitted(
                  submitted_images,
                  submitted_videos,
                  submitted_features,
                  submitted_technologies
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
        new_product_features.forEach((feature) => {
          if (!old_product_features.includes(feature)) {
            axios
              .delete(
                api_url +
                  "portofolio/item/" +
                  product.id +
                  "/feature/" +
                  feature,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                console.log("added success");
                CheckSubmitted(
                  submitted_images,
                  submitted_videos,
                  submitted_features,
                  submitted_technologies
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
        new_product_technologies.forEach((technology) => {
          if (!old_product_technologies.includes(technology)) {
            axios
              .delete(
                api_url +
                  "portofolio/item/" +
                  product.id +
                  "/technology/" +
                  technology,
                {
                  headers: {
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                console.log("added success");
                CheckSubmitted(
                  submitted_images,
                  submitted_videos,
                  submitted_features,
                  submitted_technologies
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });

        images.map((image) => {
          const formData = new FormData();
          formData.append("image", image.file);

          axios
            .post(
              api_url + "portofolio/item/" + response.data.id + "/image",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: token,
                },
              }
            )
            .then((response) => {
              submitted_images++;
              CheckSubmitted(
                submitted_images,
                submitted_videos,
                submitted_features,
                submitted_technologies
              );
            })
            .catch((error) => {
              console.log(error);
            });
        });
        videos.map((video) => {
          const formData = new FormData();
          formData.append("video", video);

          axios
            .post(
              api_url + "portofolio/item/" + response.data.id + "/video",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: token,
                },
              }
            )
            .then((response) => {
              submitted_videos++;
              CheckSubmitted(
                submitted_images,
                submitted_videos,
                submitted_features,
                submitted_technologies
              );
            })
            .catch((error) => {
              console.log(error);
            });
        });
        addedTechnologies.map((technology) => {
          axios
            .post(
              api_url + "portofolio/item/" + response.data.id + "/technology",
              {
                name: technology.name,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((response) => {
              submitted_technologies++;
              CheckSubmitted(
                submitted_images,
                submitted_videos,
                submitted_features,
                submitted_technologies
              );
            })
            .catch((error) => {
              console.log(error);
            });
        });
        features.map((feature) => {
          axios
            .post(
              api_url + "portofolio/item/" + response.data.id + "/feature",
              {
                name: feature,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((response) => {
              submitted_features++;
              CheckSubmitted(
                submitted_images,
                submitted_videos,
                submitted_features,
                submitted_technologies
              );
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function CheckSubmitted(
    submitted_images,
    submitted_videos,
    submitted_features,
    submitted_technologies
  ) {
    if (
      submitted_images === images.length &&
      submitted_videos === videos.length &&
      submitted_features === features.length &&
      submitted_technologies === addedTechnologies.length
    ) {
      dispatch(setNotification("Produsul a fost modificat cu succes!"));
      navigate("/admin/portofolio");
    }
  }

  return (
    <div className="px-20 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-4">
      <div className="flex justify-between sm:flex-col">
        <h1 className="w-fit  sm:text-2xl">Formular modificare produs</h1>
        <button
          className="regular-btn px-6 pt-1"
          onClick={SubmitPortofolioItemChanges}
        >
          Modifică produsul
        </button>
      </div>
      <p className="text-xl sm:mt-2">
        Editează următorul formular pentru a modifica produsul din portofoliu.
      </p>
      <form className="flex mt-2 gap-x-4 sm:flex-col gap-y-2">
        <div className="w-1/4 md:w-1/2 sm:w-full">
          <div className="flex flex-col gap-y-2">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nume categorie"
              className=" block h-fit !min-w-0"
              type="text"
            />
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              placeholder="Descriere"
              className="block h-fit !min-w-0 min-h-[10.25rem]"
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Link proiect"
          />
          <select
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            className="h-[2.1rem] cursor-pointer"
          >
            <option value="" disabled selected>
              Selectează categoria
            </option>
            {categories.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
      </form>

      <label
        htmlFor="image_file"
        className="regular-btn mt-4 block w-fit cursor-pointer px-6 pt-1 sm:w-full"
      >
        Adaugă imagine
        <input
          id="image_file"
          onChange={selectFile}
          type="file"
          className="hidden"
          accept="image/*"
        />
      </label>
      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 mt-2 gap-x-4 gap-y-2 w-full overflow-x-hidden">
        {product.images &&
          product.images.map((image, index) => (
            <div className="relative group regular-border">
              <img
                className="w-full"
                src={media_url + image.image}
              />
              <img
                onClick={() => {
                  const newImages = [...product.images];
                  newImages.splice(index, 1);
                  product.images = newImages;
                  setProduct({ ...product });
                }}
                src={x}
                className="w-7 p-2 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
              />
            </div>
          ))}
        {images.map((image, index) => (
          <div className="relative group regular-border">
            <img className="w-full" src={image.image} />
            <img
              onClick={() => {
                const newImages = [...images];
                newImages.splice(index, 1);
                setImages(newImages);
              }}
              src={x}
              className="w-7 p-2 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
            />
          </div>
        ))}
      </div>
      <form onSubmit={AddVideo} className="flex mt-4 sm:flex-col gap-y-2">
        <button className="regular-btn  px-6 pt-1">Adaugă video</button>
        <input
          placeholder="Google Drive link"
          type="text"
          value={newVideo}
          onChange={(e) => setNewVideo(e.target.value)}
        />
      </form>
      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-4 gap-y-2 mt-2">
        {product.videos &&
          product.videos.map((video, index) => (
            <div className="relative group regular-border">
              <video controls>
                <source
                  src={"https://drive.google.com/uc?id=" + video.video}
                  type="video/mp4"
                />
              </video>
              <img
                onClick={() => {
                  const newVideos = [...product.videos];
                  newVideos.splice(index, 1);
                  product.videos = newVideos;
                  setProduct({ ...product });
                }}
                src={x}
                className="w-7 p-2 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
              />
            </div>
          ))}
        {videos.map((video, index) => (
          <div className=" group regular-border relative pb-[54%]">
            {/* <video controls>
             <source
               src={
                 "https://drive.google.com/file/d/1fruNdl2sEfCPsGzRL0PKsYHod5y5_wfu/preview"
               }
               type="video/mp4"
             />
           </video> */}
            <iframe
              src={`https://drive.google.com/file/d/${video}/preview`}
              className="w-full h-full absolute top-0 left-0 bottom-0 right-0"
              // style="width:100%;height:100%;position:absolute;left:0px;top:0px;"
              frameborder="0"
              width="100%"
              height="100%"
              allowfullscreen="true"
            ></iframe>
            <img
              onClick={() => {
                const newVideos = [...videos];
                newVideos.splice(index, 1);
                setVideos(newVideos);
              }}
              src={x}
              className="w-7 p-2 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
            />
          </div>
        ))}
      </div>
      <form onSubmit={AddTechnology} className="flex mt-4 sm:flex-col gap-y-2">
        <button className="regular-btn  px-6 pt-1">Adaugă tehnologie</button>
        <select name="technology" className="h-[2.1rem] cursor-pointer">
          <option value="" disabled selected>
            Selectează tehnologia
          </option>
          {technologies.map((technology) => (
            <option value={technology.name}>{technology.name}</option>
          ))}
        </select>
      </form>
      <div className="flex flex-wrap gap-x-4 gap-y-2 mt-2">
        {product.technologies &&
          product.technologies.map((technology) => (
            <div className="regular-border px-4 py-1 flex gap-x-2 relative group">
              <img
                src={technologies.find((t) => t.name == technology.name).icon}
                className="w-5"
              />
              <div className="mt-1 text-xl font-medium">{technology.name}</div>
              <img
                onClick={() => {
                  const newTechnologies = [...product.technologies];
                  newTechnologies.splice(
                    product.technologies.indexOf(technology),
                    1
                  );
                  product.technologies = newTechnologies;
                  setProduct({ ...product });
                }}
                src={x}
                className="w-5 p-1.5 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
              />
            </div>
          ))}
        {addedTechnologies.map((technology) => (
          <div className="regular-border px-4 py-1 flex gap-x-2 relative group">
            <img src={technology.icon} className="w-5" />
            <div className="mt-1 text-xl font-medium">{technology.name}</div>
            <img
              onClick={() => {
                const newTechnologies = [...addedTechnologies];
                newTechnologies.splice(
                  addedTechnologies.indexOf(technology),
                  1
                );
                setAddedTechnologies(newTechnologies);
              }}
              src={x}
              className="w-5 p-1.5 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
            />
          </div>
        ))}
      </div>
      <form onSubmit={AddFeature} className="flex mt-4 sm:flex-col gap-y-2">
        <button className="regular-btn  px-6 pt-1">
          Adaugă caracteristică
        </button>
        <input
          placeholder="Caracteristică"
          type="text"
          value={newFeature}
          onChange={(e) => setNewFeature(e.target.value)}
        />
      </form>
      <div className="flex flex-wrap gap-x-4 gap-y-2 mt-2">
        {product.features &&
          product.features.map((feature) => (
            <div className="regular-border px-4 py-1 flex gap-x-2 relative group">
              <div className="mt-1 font-medium">{feature.name}</div>
              <img
                onClick={() => {
                  const newFeatures = [...product.features];
                  newFeatures.splice(product.features.indexOf(feature), 1);
                  product.features = newFeatures;
                  setProduct({ ...product });
                }}
                src={x}
                className="w-5 p-1.5 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
              />
            </div>
          ))}
        {features.map((feature) => (
          <div className="regular-border px-4 py-1 flex gap-x-2 relative group">
            <div className="mt-1 font-medium">{feature}</div>
            <img
              onClick={() => {
                const newFeatures = [...features];
                newFeatures.splice(features.indexOf(feature), 1);
                setFeatures(newFeatures);
              }}
              src={x}
              className="w-5 p-1.5 bg-secondary transition-opacity ease-in-out duration-300 group-hover:opacity-100 opacity-0 cursor-pointer absolute top-0 right-0"
            />
          </div>
        ))}
      </div>
      <ImageCropModal
        aspectRatio={16 / 9}
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </div>
  );
};

export default AdminPortofolioProduct;
