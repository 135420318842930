import React, { useEffect } from "react";
import python from "../static/images/python.svg";
import javascript from "../static/images/javascript.svg";
import html from "../static/images/html.svg";
import css from "../static/images/css.svg";
import django from "../static/images/django.svg";
import react from "../static/images/react.svg";
import dart from "../static/images/dart.svg";
import sql from "../static/images/sql.svg";
import flutter from "../static/images/flutter.svg";
import bootstrap from "../static/images/bootstrap.svg";
import tailwind from "../static/images/tailwind.svg";
import { motion } from "framer-motion";
function Technologies() {
  const technologies = [
    {
      name: "Creare aplicație web",
      description:
        "Pentru partea de frontend folosim HTML, CSS (cu sau fără Tailwind sau Bootstrap), Javascript (cu sau fără ReactJS). Pentru partea de backend folosim Python (cu Django), iar pentru baza de date SQL.",
    },
    {
      name: "Creare aplicație mobilă",
      description:
        "Deoarece dorim ca aplicațiile create să fie compatibile atât pe telefoanele cu iOS, cât și pe cele cu Android, folosim Dart (cu Flutter) pentru a le dezvolta. ",
    },
    {
      name: "Creare chatbot",
      description:
        "Pentru boții pe Telegram folosim Python, iar pentru boții pe Messenger - Javascript. Folosim fiecare limbaj niște pachete speciale ce ne permit crearea acestora.",
    },
    {
      name: "Creare magazin Shopify",
      description:
        "Pentru structura și stilurile paginilor, folosim HTML și CSS (cu sau fără Tailwind). Pentru logica paginilor folosim Javascript, iar ca limbaj de templating - Liquid.",
    },
    {
      name: "Creare și promovare brand",
      description:
        "Pentru editarea videoclipurilot folosim Camtasia Studio sau Adobe Premiere Pro. Pentru editarea de logo, flyer, afiș, poster, etc. folosim Adobe Photoshop. Pentru optimizare SEO folosim extensii de browser dedicate.",
    },
  ];

  return (
    <div className="flex lg:flex-col md:flex-col sm:flex-col justify-between px-36 mt-12 mb-16 xl:px-10 md:px-10 sm:px-10">
      <div className="w-[40%] xl:w-[45%] lg:w-[80%] sm:mx-auto sm:order-2 sm:mt-12 lg:mx-auto lg:mt-12 md:mt-12 md:order-2 md:mx-auto md:w-[80%] sm:w-full lg:order-2">
        <div className="w-full grid grid-cols-4 gap-x-16 sm:gap-x-6 justify-between">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0 } }}
            src={python}
            className="w-40 min-h-[8rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.15 } }}
            src={javascript}
            className="w-40 min-h-[8rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.3 } }}
            src={html}
            className="w-40 min-h-[8rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.45 } }}
            src={css}
            className="w-40 min-h-[8rem]"
          />
        </div>
        <div className="w-[70%] mt-12 mx-auto grid grid-cols-3 sm:gap-x-6 gap-x-20 justify-between">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.6 } }}
            src={django}
            className="w-32 min-h-[6rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.75 } }}
            src={react}
            className="w-32 min-h-[6rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.9 } }}
            src={dart}
            className="w-32 min-h-[6rem]"
          />
        </div>
        <div className="w-[45%] mt-12 mx-auto grid grid-cols-3 sm:gap-x-6 gap-x-14 justify-between">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1.05 } }}
            src={sql}
            className="w-28 min-h-[4rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1.2 } }}
            src={flutter}
            className="w-28 min-h-[4rem]"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1.35 } }}
            src={bootstrap}
            className="w-28 min-h-[4rem]"
          />
        </div>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 1.5 } }}
          src={tailwind}
          className="w-16 mt-12 mx-auto min-h-[3rem]"
        />
      </div>
      <div className="w-[55%] mt-1 xl:w-1/2 lg:w-full md:w-full sm:w-full">
        <h1 className="text-3xl font-bold">Tehnologii moderne de programare</h1>
        <div className="mt-8 flex flex-col gap-y-6">
          {technologies.map((technology, index) => (
            <div key={index}>
              <div className="flex items-center">
                <div className="h-1 w-10 bg-secondary mr-4"></div>
                <h2 className="font-medium text-xl">{technology.name}</h2>
              </div>
              <p className="mt-1">{technology.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Technologies;
