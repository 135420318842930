import React, { useEffect } from "react";
import book from "../static/images/book.svg";
import axios from "axios";
import { api_url } from "../functions/constants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../store/ErrorSlice";
import { setOrder } from "../store/OrderSlice";
function ViewOrder() {
  const [orderName, setOrderName] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function VerifyOrderHandler(e) {
    e.preventDefault();
    const code = e.target.code.value;
    const email = e.target.email.value;
    axios
      .get(`${api_url}order/${code}?email=${email}`)
      .then((res) => {
        setOrderName(res.data.name);
        dispatch(setOrder({ code, email }));
        setTimeout(() => {
          navigate(`/order/${code}`);
        }, 1500);
      })
      .catch((err) => {
        dispatch(setError(err.response.data.detail));
      });
  }
  return (
    <div className="mt-20 mb-20">
      <h1 className="text-2xl font-bold w-fit mx-auto">Vizualizează comanda</h1>
      <p className="text-lg w-fit mx-auto text-center px-4">
        Introdu codul secret al comenzii pentru a vizualiza toate toate
        detaliile și progresul proiectului
      </p>
      <form
        onSubmit={VerifyOrderHandler}
        className="mx-auto w-fit mt-4 flex flex-col gap-y-4"
      >
        <input type="email" placeholder="Email" name="email" />
        <input type="text" placeholder="Codul comenzii" name="code" />
        <button className="regular-btn">Verifică codul</button>
      </form>
      <div
        className={
          "mx-auto relative w-32 mt-20 transition-transform ease-in-out duration-300 " +
          (orderName !== "" ? "scale-110" : "")
        }
      >
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: "100%" }}
          src={book}
          alt="book"
          className="w-full"
        />
        {orderName !== "" && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-1/3 left-1/2 translate-x-[-65%] w-fit text-sm text-center text-primary"
          >
            {orderName.length > 20 ? orderName.slice(0, 20) + "..." : orderName}
          </motion.p>
        )}
      </div>
    </div>
  );
}

export default ViewOrder;
