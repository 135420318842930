import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../functions/constants";
import { motion } from "framer-motion";
import LoadingSpinner from "../components/LoadingSpinner";
import PageNotLoaded from "../components/PageNotLoaded";
import LeftColorButton from "../components/LeftColorButton";
import { Link } from "react-router-dom";
import { technologies } from "../functions/constants";
function FrequentlyAskedQuestions({ title, description }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [services, setServices] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(api_url + "services/questions")
      .then((response) => {
        setServices(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  const explanations = [
    {
      name: "Creare aplicație web",
      description:
        "Pentru partea de frontend folosim HTML, CSS (cu sau fără Tailwind sau Bootstrap), Javascript (cu sau fără ReactJS). Pentru partea de backend folosim Python (cu Django), iar pentru baza de date SQL.",
    },
    {
      name: "Creare aplicație mobilă",
      description:
        "Deoarece dorim ca aplicațiile create să fie compatibile atât pe telefoanele cu iOS, cât și pe cele cu Android, folosim Dart (cu Flutter) pentru a le dezvolta. ",
    },
    {
      name: "Creare chatbot",
      description:
        "Pentru boții pe Telegram folosim Python, iar pentru boții pe Messenger - Javascript. Folosim fiecare limbaj niște pachete speciale ce ne permit crearea acestora.",
    },
    {
      name: "Creare magazin Shopify",
      description:
        "Pentru structura și stilurile paginilor, folosim HTML și CSS (cu sau fără Tailwind). Pentru logica paginilor folosim Javascript, iar ca limbaj de templating - Liquid.",
    },
    {
      name: "Creare și promovare brand",
      description:
        "Pentru editarea videoclipurilot folosim Camtasia Studio sau Adobe Premiere Pro. Pentru editarea de logo, flyer, afiș, poster, etc. folosim Adobe Photoshop. Pentru optimizare SEO folosim extensii de browser dedicate.",
    },
  ];

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div className="px-36 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-4">
      <h1 className="text-2xl font-bold text-dark">{title}</h1>
      <p className="text-lg font-medium text-dark">{description}</p>
      <div className="mt-4 grid grid-cols-2 justify-between gap-x-14 gap-y-16 lg:gap-x-8 md:gap-x-4 md:gap-y-8 sm:grid-cols-1 sm:gap-y-8">
        {services &&
          services.map((service, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: index * 0.25 } }}
              key={service.id}
              className="p-4 relative regular-border h-fit"
            >
              <Link to={"/service/" + service.id}>
                <LeftColorButton>
                  <h2 className="uppercase">{service.name}</h2>
                </LeftColorButton>
              </Link>
              <div className="flex flex-col gap-y-3 mt-4">
                {service.questions &&
                  service.questions.map((question, index) => (
                    <div key={question.id} className="">
                      <p
                        onClick={() =>
                          selectedQuestion === question.id
                            ? setSelectedQuestion(null)
                            : setSelectedQuestion(question.id)
                        }
                        className={
                          "cursor-pointer text-dark font-semibold text-lg" +
                          (index === 0 ? " w-3/4" : "")
                        }
                      >
                        {question.question}
                      </p>

                      <motion.p
                        initial={{ height: 0 }}
                        animate={{
                          height: question.id === selectedQuestion ? "auto" : 0,
                        }}
                        className={"mt-1 scrollbar-none overflow-auto"}
                      >
                        {question.answer}
                      </motion.p>
                    </div>
                  ))}
              </div>
            </motion.div>
          ))}
      </div>
      <h2 className="text-xl font-bold text-dark mt-16">
        Tehnologiile pe care le folosim
      </h2>
      <div className="flex gap-x-6 gap-y-4 mt-4 flex-wrap">
        {technologies.map((technology) => (
          <div key={technology.id} className="flex gap-x-2 items-center">
            <img
              className="w-8 h-8"
              src={technology.icon}
              alt={technology.name}
            />
            <p className="text-dark font-semibold">{technology.name}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-y-2 mt-4">
        {explanations.map((explanation, index) => (
          <div key={index}>
            <div className="flex items-center">
              <div className="h-1 w-10 bg-secondary mr-4"></div>
              <h2 className="font-medium text-xl">{explanation.name}</h2>
            </div>
            <p className="mt-1">{explanation.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FrequentlyAskedQuestions;
