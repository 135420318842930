import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setPage } from "../../store/AdminPageSlice.js";
import { useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../../functions/constants.js";
import edit from "../../static/images/edit.svg";
import CategoryCard from "../../components/CategoryCard.js";
const AdminPortofolio = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    dispatch(setPage("Portofoliu"));
    axios.get(api_url + "portofolio/categories").then((response) => {
      setCategories(response.data);
    });
  }, []);
  return (
    <div className="px-20 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-2">
      <div className="flex gap-x-8 sm:flex-col gap-y-2">
        <Link
          to="/admin/portofolio/category/add"
          type="submit"
          className="regular-btn text-base px-12"
        >
          Adaugă categorie
        </Link>
        <Link
          to="/admin/portofolio/product/add"
          type="submit"
          className="regular-btn text-base px-12"
        >
          Adaugă produs
        </Link>
      </div>
      <div
        className={
          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-y-4 mt-6 gap-x-4"
        }
      >
        {categories.map((category) => (
          <CategoryCard category={category} />
        ))}
      </div>
    </div>
  );
};

export default AdminPortofolio;
