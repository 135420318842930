import React, { useEffect, useState } from "react";
import { setPage } from "../../store/AdminPageSlice.js";
import { useDispatch } from "react-redux";
const AdminStatistics = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPage("Statistici"));
  }, []);
  return <div>AdminStatistics</div>;
};

export default AdminStatistics;
