import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../functions/constants.js";
import { useNavigate } from "react-router-dom";
import LeftColorButton from "../components/LeftColorButton.js";
import check from "../static/images/check.svg";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../components/LoadingSpinner.js";
import PageNotLoaded from "../components/PageNotLoaded.js";
import ReCAPTCHA from "react-google-recaptcha";
import { setError } from "../store/ErrorSlice.js";

function PackageForm() {
  const packageId = useSelector((state) => state.package);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);
  const [isOrderSending, setIsOrderSending] = useState(false);
  const [isOrderError, setIsOrderError] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [allIsAnswered, setAllIsAnswered] = useState(false);

  const [userData, setUserData] = useState({});
  const [packageData, setPackageData] = useState(null);

  const [answers, setAnswers] = useState([]);

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  function onChange(value) {
    if (value) setIsRecaptchaVerified(true);
    else setIsRecaptchaVerified(false);
  }

  useEffect(() => {
    if (packageId) {
      axios
        .get(api_url + "package/" + packageId)
        .then((response) => {
          setPackageData(response.data);
          setCurrentQuestion(response.data.questions[0]);
          setCurrentQuestionIndex(0);
        })
        .catch((error) => {
          navigate("/services");
        });
    } else {
      navigate("/services");
    }
  }, []);

  function SubmitUserDataHandler(e) {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const name_surname = form.name_surname.value;
    const phone = form.phone.value;
    const email = form.email.value;
    const company = form.company.value ? form.company.value : "";

    const data = {
      name: name,
      name_surname: name_surname,
      phone: phone,
      email: email,
      company: company,
    };

    setUserData(data);
  }

  function NextQuestionHandler(e, option = null) {
    e.preventDefault();
    const data = {
      question: currentQuestion.question,
      question_id: currentQuestionIndex,
      answer: option ? option : currentAnswer,
    };
    if (data.answer === "") return alert("Trebuie să răspunzi la întrebare");
    var index = answers.findIndex(
      (answer) => answer.question_id === currentQuestionIndex
    );
    if (index !== -1) {
      const newAnswers = answers;
      newAnswers[index] = data;
      setAnswers([...newAnswers]);
    } else {
      setAnswers([...answers, data]);
    }

    if (currentQuestionIndex < packageData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestion(packageData.questions[currentQuestionIndex + 1]);
      if (CheckIfAnswered(currentQuestionIndex + 1) !== -1)
        setCurrentAnswer(
          answers[CheckIfAnswered(currentQuestionIndex + 1)].answer
        );
      else setCurrentAnswer("");
    } else {
      setAllIsAnswered(true);
    }
  }

  function PreviousQuestionHandler(e) {
    e.preventDefault();
    if (currentQuestionIndex > 0) {
      setCurrentAnswer(answers[currentQuestionIndex - 1].answer);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrentQuestion(packageData.questions[currentQuestionIndex - 1]);
    }
  }

  function CheckIfAnswered(question_id) {
    const index = answers.findIndex(
      (answer) => answer.question_id === question_id
    );
    if (index !== -1) {
      return index;
    } else {
      return -1;
    }
  }

  function SubmitAnswers() {
    const data = {
      package_id: packageId,
      name: userData.name,
      client_name: userData.name_surname,
      client_phone: userData.phone,
      client_email: userData.email,
      client_company: userData.company ? userData.company : "",
      answers: answers,
    };
    if (!isRecaptchaVerified) {
      dispatch(setError("Trebuie să bifezi că nu ești robot"));
      return;
    }
    setIsOrderSending(true);
    setIsOrderError(false);
    axios
      .post(api_url + "order", data)
      .then((response) => {
        setOrderId(response.data.order_id);
        setIsOrderSending(false);
      })
      .catch((error) => {
        setIsOrderSending(false);
        setIsOrderError(true);
      });
  }
  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  function CopyCodeHandler() {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(orderId);
    } else {
      unsecuredCopyToClipboard(orderId);
    }
    setCodeCopied(true);
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>
          Formular pentru pachetul {packageData ? packageData.name : ""}
        </title>
      </Helmet>
      {isOrderError ? (
        <PageNotLoaded className="absolute-center">
          A apărut o problemă la trimiterea comenzii. Încearcă mai târziu sau{" "}
          <span
            onClick={SubmitAnswers}
            className="font-semibold cursor-pointer"
          >
            retrimite
          </span>
          .
        </PageNotLoaded>
      ) : isOrderSending ? (
        <LoadingSpinner className={"absolute-center"} />
      ) : (
        <div className="mb-16">
          {orderId !== null ? (
            <div className="px-4">
              <img
                alt={"check-icon"}
                src={check}
                className="w-24 mx-auto mt-12"
              />
              <h1 className="w-fit mx-auto mt-4 text-2xl text-center">
                Comanda ta a fost plasată cu succes!
              </h1>
              <p className="w-fit mx-auto mt-2 text-lg text-center">
                Puteți vedea în timp real statusul comenzii utilizând codul de
                mai jos.
                <br />
                Acesta a fost trimis și prin email.
              </p>
              <div
                onClick={CopyCodeHandler}
                className={
                  "select-text font-bold text-3xl mt-8 w-fit mx-auto relative group cursor-pointer pt-1 px-2 transition-all ease-in-out duration-300" +
                  (codeCopied ? " bg-secondary text-primary" : "")
                }
              >
                {orderId}
                <div className="absolute left-0 bottom-0 w-0 h-0.75 bg-secondary group-hover:w-full transition-all ease-in-out duration-300"></div>
              </div>
            </div>
          ) : packageData ? (
            <div>
              <h1 className="w-fit mx-auto mt-12 text-2xl text-center sm:px-5">
                {packageData.name}
              </h1>
              {allIsAnswered ? (
                <div>
                  <p className="w-fit mx-auto text-lg text-center">
                    Acestea sunt răspunsurile tale
                  </p>
                  <div className="w-fit sm:w-full mx-auto mt-4 flex flex-col gap-y-6 px-4">
                    {answers.map((answer) => (
                      <div className="mt-4 flex flex-col gap-y-6">
                        <div>
                          <p className="w-fit text-lg font-semibold sm:w-full">
                            {packageData.questions[answer.question_id].question}
                          </p>
                          <p className="w-fit font-normal sm:w-full">
                            {answer.answer}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div className="w-[30rem] mx-auto sm:w-full">
                      <button
                        onClick={(e) => {
                          setAllIsAnswered(false);
                          PreviousQuestionHandler(e);
                        }}
                        className="mb-2 regular-btn w-full h-fit"
                      >
                        {"<- Întrebarea precedentă"}
                      </button>
                      <span onClick={SubmitAnswers}>
                        <LeftColorButton text_center={true}>
                          TRIMITE CEREREA
                        </LeftColorButton>
                      </span>
                      <div className="mt-2">
                        <ReCAPTCHA
                          sitekey="6LfvneomAAAAAI9pKz_RstfdHFANjs0pLeLdNgxX"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : userData.name ? (
                <div>
                  <p className="w-fit mx-auto text-lg text-center sm:px-5">
                    Răspunde la următoarele întrebări pentru a înțelege produsul
                    pe care dorești să ți-l construim (
                    {currentQuestionIndex + 1}/{packageData.questions.length})
                  </p>
                  <p className="mx-auto text-xl font-medium mt-8 sm:px-5 text-center w-2/3 sm:w-[90%] md:w-3/4">
                    {currentQuestion.question}
                  </p>
                  {currentQuestion.type === "options" ? (
                    <div className="w-fit mx-auto mt-4 flex flex-col gap-y-6">
                      {currentQuestion.options.map((option) => (
                        <button
                          onClick={(e) => NextQuestionHandler(e, option.option)}
                          className={
                            "inline-block mx-4 relative group regular-border " +
                            (CheckIfAnswered(currentQuestion) &&
                            answers[currentQuestionIndex] &&
                            answers[currentQuestionIndex].answer ===
                              option.option
                              ? " bg-secondary text-primary"
                              : "")
                          }
                        >
                          <div className="bottom-0 left-0 w-full absolute h-0 bg-secondary group-hover:h-full ease-in-out duration-200 animation-all"></div>
                          <div className="block px-4 text-center bottom-0 left-0 w-full group-hover:mix-blend-lighten delay-150 pt-0.5 group-hover:text-primary ease-in-out duration-200 animation-all">
                            {option.option}
                          </div>
                        </button>
                      ))}
                      {currentQuestionIndex !== 0 ? (
                        <button
                          onClick={PreviousQuestionHandler}
                          className="regular-btn w-fit h-fit min-w-[15rem] sm:w-full px-4"
                        >
                          {"<- Întrebarea precedentă"}
                        </button>
                      ) : null}
                    </div>
                  ) : currentQuestion.type === "short" ? (
                    <form className="flex flex-col w-1/3 lg:w-1/2 md:w-2/3 sm:w-[90%] mx-auto gap-y-4">
                      <input
                        value={currentAnswer}
                        onChange={(e) => setCurrentAnswer(e.target.value)}
                        className="sm:w-full sm:!min-w-[15rem] !min-w-[30rem] mx-auto mt-4 w-fit"
                        type="text"
                        placeholder="Răspuns"
                        name="answer"
                      />
                      <div className="flex gap-y-4 sm:w-full mx-auto h-fit gap-x-4 sm:flex-col">
                        {currentQuestionIndex !== 0 ? (
                          <button
                            onClick={PreviousQuestionHandler}
                            className="regular-btn w-fit h-fit min-w-[15rem] sm:w-full px-4"
                          >
                            {"<- Întrebarea precedentă"}
                          </button>
                        ) : null}
                        <button
                          onClick={NextQuestionHandler}
                          className="regular-btn w-fit min-w-[15rem] h-fit sm:w-full px-4"
                        >
                          {currentQuestionIndex + 1 ===
                          packageData.questions.length
                            ? "Revizuie răspunsurile"
                            : "Întrebarea următoare ->"}
                        </button>
                      </div>
                    </form>
                  ) : currentQuestion.type === "long" ? (
                    <form className="flex flex-col w-fit mx-auto gap-y-4">
                      <textarea
                        value={currentAnswer}
                        onChange={(e) => setCurrentAnswer(e.target.value)}
                        className="mx-auto mt-4 h-40 w-[30rem] sm:w-full"
                        type="text"
                        placeholder="Răspuns"
                        name="answer"
                      />
                      <div className="flex h-fit gap-x-4 sm:flex-col sm:gap-y-4">
                        {currentQuestionIndex !== 0 ? (
                          <button
                            onClick={PreviousQuestionHandler}
                            className="regular-btn w-full h-fit"
                          >
                            {"<- Întrebarea precedentă"}
                          </button>
                        ) : null}
                        <button
                          onClick={NextQuestionHandler}
                          className="regular-btn w-full h-fit"
                        >
                          {currentQuestionIndex + 1 ===
                          packageData.questions.length
                            ? "Revizuie răspunsurile"
                            : "Întrebarea următoare ->"}
                        </button>
                      </div>
                    </form>
                  ) : null}
                </div>
              ) : (
                <div>
                  <p className="w-fit mx-auto text-lg text-center sm:px-5">
                    Completați următorul formular pentru a plasa comanda.
                  </p>
                  <form
                    className="w-fit mx-auto mt-12 flex flex-col gap-y-6"
                    onSubmit={SubmitUserDataHandler}
                  >
                    <input
                      required
                      className="mx-auto"
                      type="text"
                      placeholder="Nume proiect"
                      name="name"
                    />
                    <input
                      required
                      className="mx-auto"
                      type="text"
                      placeholder="Nume Prenume"
                      name="name_surname"
                    />
                    <input
                      required
                      className="mx-auto"
                      type="text"
                      placeholder="Număr telefon"
                      name="phone"
                    />
                    <input
                      required
                      className="mx-auto"
                      type="email"
                      placeholder="Adresă email"
                      name="email"
                    />
                    <input
                      className="mx-auto"
                      type="text"
                      placeholder="Nume companie (opțional)"
                      name="company"
                    />
                    <button>
                      <LeftColorButton text_center={true}>
                        MERGI LA ETAPA URMĂTOARE
                      </LeftColorButton>
                    </button>
                  </form>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default PackageForm;
