import instagram_primary from "../static/images/instagram-primary.svg";
import facebook_primary from "../static/images/facebook-primary.svg";
import youtube_primary from "../static/images/youtube-primary.svg";
import tiktok_primary from "../static/images/tiktok-primary.svg";
import instagram from "../static/images/instagram.svg";
import facebook from "../static/images/facebook.svg";
import youtube from "../static/images/youtube.svg";
import tiktok from "../static/images/tiktok.svg";
import GetGoogleDriveId from "./GetGoogleDriveId";
import react from "../static/images/react.svg";
import python from "../static/images/python.svg";
import django from "../static/images/django.svg";
import javascript from "../static/images/javascript.svg";
import typescript from "../static/images/typescript.svg";
import html from "../static/images/html.svg";
import css from "../static/images/css.svg";
import tailwindcss from "../static/images/tailwind.svg";
import bootstrap from "../static/images/bootstrap.svg";
import sass from "../static/images/sass.svg";
import nodejs from "../static/images/nodejs.svg";
import liquid from "../static/images/liquid.svg";
import flutter from "../static/images/flutter.svg";
import dart from "../static/images/dart.svg";
import sql from "../static/images/sql.svg";
import photoshop from "../static/images/photoshop.svg";
import premiere from "../static/images/premiere.svg";

const debug = false;
export const api_url = debug ? "http://localhost:8000/api/" : "/api/";
export const auth_url = debug ? "http://localhost:8000/auth/" : "/auth/";
export const jwt_url = debug ? "http://localhost:8000/jwt/" : "/jwt/";
export const media_url = debug ? "http://localhost:8000/api" : "";

export const social_media_primary = [
  {
    icon: instagram_primary,
    link: "",
    alt: "instagram",
    name: "@scriptify_ro",
  },
  {
    icon: facebook_primary,
    link: "",
    alt: "facebook",
    name: "Scriptify Web",
  },
  {
    icon: youtube_primary,
    link: "",
    alt: "youtube",
    name: "@Scriptify_ro",
  },
  {
    icon: tiktok_primary,
    link: "",
    alt: "tiktok",
    name: "@scriptifyweb",
  },
];

export const social_media = [
  {
    icon: instagram,
    link: "",
    alt: "instagram",
    name: "@scriptify_ro",
  },
  {
    icon: facebook,
    link: "",
    alt: "facebook",
    name: "Scriptify Web",
  },
  {
    icon: youtube,
    link: "",
    alt: "youtube",
    name: "@Scriptify_ro",
  },
  {
    icon: tiktok,
    link: "",
    alt: "tiktok",
    name: "@scriptifyweb",
  },
];

export const history_urls = [
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1HiMthAozz5zyzPH3s-xc_KCUzKvPhoYs/view?usp=sharing"
      ),
  },
  {
    type: "mobile",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1OaIKinlCSKCBeLhw6O0KOWWUvHwAoju_/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/16I1-yEoEl58fZmtQmCdTQ3krBqmBHc_u/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1-wWyAcAJKAzQU0l2E2Y9o7KvCLbj4ia5/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1OVEEdVSsM0RapaJ46OjBRTeQKC1GDAKM/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1XwObBLwiBFV-VUmnjV3QNMJiCmOKuCxo/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1m62tmkpQTxJsTzbJk_Y454KRFAU2wdr6/view?usp=sharing"
      ),
  },

  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1iNgdOPFr8BlzhS8Go9I8u43RVzbQ-AHh/view?usp=sharing"
      ),
  },
  {
    type: "desktop",
    url:
      "https://drive.google.com/uc?id=" +
      GetGoogleDriveId(
        "https://drive.google.com/file/d/1WeCqWfn4gR0jmw0aCsL0fZjWpqcbBtnn/view?usp=sharing"
      ),
  },
];

// export const video_url = 'https://firebasestorage.googleapis.com/v0/b/scriptify-8aebf.appspot.com/o/Video.mp4?alt=media&token=7803babc-025c-4d41-845f-181a9d222bcc&_gl=1*1fzztyr*_ga*MTUzMzEwMTc3MS4xNjg2MzgzOTE1*_ga_CW55HF8NVT*MTY4NjM4MzkxNC4xLjEuMTY4NjM4NDM0OS4wLjAuMA';
//export const video_url = "https://drive.google.com/uc?id="+GetGoogleDriveId("https://drive.google.com/file/d/1udu0fyipKlVK5ZsQlHB8GLRc6J-5itKw/view?usp=drive_link")
export const video_url =
  "https://drive.google.com/uc?id=" +
  GetGoogleDriveId(
    "https://drive.google.com/file/d/1-NHntmldwxz5Z33ySaQN0tlXZ8JYU_f5/view?usp=drive_link"
  );

export const faq = [
  {
    question:
      "De ce ar trebui să apelez la serviciile voastre de creare a website-urilor?",
    answer:
      "La Scriptify, avem o echipă specializată în crearea de website-uri profesionale și eficiente. Motivele pentru care ar trebui să apelezi la serviciile noastre includ: experiență și expertiză în dezvoltarea web, design atrăgător și funcționalitate impecabilă, personalizare și adaptabilitate la nevoile afacerii tale, optimizare SEO și suport tehnic pe termen lung. Vom crea un produs care să-ți reprezinte afacerea.",
  },
  {
    question:
      "Cum pot beneficia de un website personalizat care să se potrivească nevoilor afacerii mele?",
    answer:
      "La Scriptify, înțelegem că fiecare afacere este unică. Prin urmare, lucrăm îndeaproape cu tine pentru a înțelege nevoile și obiectivele afacerii tale. Vom crea un website personalizat, adaptat specific cerințelor tale și valorilor brandului tău. De la designul estetic până la funcționalitatea și caracteristicile dorite, vei beneficia de un produs unic.",
  },
  {
    question:
      "Puteți dezvolta aplicații mobile pentru mai multe platforme, cum ar fi iOS și Android?",
    answer:
      "Da, la Scriptify suntem specializați în dezvoltarea aplicațiilor mobile pentru diverse platforme, inclusiv iOS și Android. Vom crea aplicații mobile captivante și ușor de utilizat, care să îți ajute afacerea să ajungă la clienții pe orice dispozitiv și platformă. Indiferent de cerințele tale, vom adapta soluția noastră pentru a te asigura că ai un produs final de calitate și performanță excelentă.",
  },
  {
    question:
      "Cum pot să încep procesul de colaborare pentru serviciile pe care le oferiți?",
    answer:
      "Pentru a începe procesul de colaborare, tot ce trebuie să faci este să ne contactezi prin secțiunea Contact, prin paginile de socializare sau, pentru a înțelege mai bine ce doriți, puteți alege serviciul, pachetul, după care veți completa un formular, iar cererea dumneavoastră va fi trimisă spre aprobare. Vom intra în contact folosind datele furnizate și vă vom ajuta să vă dezvoltați prosului. Website-ul deține un sistem de vizualizare în timp real a progresul proiectului prin secțiunea Vizualizează comanda.",
  },
  {
    question: "Oferiți suport tehnic după finalizarea proiectului?",
    answer:
      "Da, la Scriptify oferim suport tehnic continuu pentru clienții noștri. După finalizarea proiectului, te vom sprijini cu orice întrebări sau solicitări de asistență tehnica. Ne asigurăm că website-ul sau aplicația ta funcționează în mod optim și putem interveni rapid în cazul apariției unor probleme tehnice. Suntem aici pentru a ne asigura că afacerea ta online rulează fără probleme. După finalizarea proiectului, dacă veți dori funcționalități adiționale, acestea vor fi achitate aparte.",
  },
  {
    question:
      "Care este timpul estimat pentru finalizarea unui proiect de dezvoltare web sau de marketing?",
    answer:
      "Durata proiectelor noastre de dezvoltare software sau de marketing poate varia în funcție de complexitatea și cerințele specifice ale fiecărui proiect. În general, stabilim un program realist și comunicăm cu tine în fiecare etapă a procesului pentru a te ține la curent cu progresul. Ne dorim să livrăm un produs final de înaltă calitate în cel mai scurt timp posibil, fără a compromite calitatea și atenția la detalii. În funcție de pachetul selectat, crearea proiectului poate dura de la câteva zile până la câteva luni.",
  },
];

export const reviews = [
  {
    name: "Andrei P.",
    review:
      "Am colaborat cu Scriptify pentru realizarea unui website pentru firma mea. Sunt foarte mulțumit de rezultatul final, de profesionalismul și promptitudinea cu care au lucrat. Recomand cu încredere!",
    rating: 5,
  },
  {
    name: "Elena M.",
    review:
      "Au creat un CRM personalizat care a optimizat complet procesele noastre de afaceri. Acum avem o gestionare mai eficientă a clienților și o colaborare mai bună în echipă.",
    rating: 5,
  },
  {
    name: "Radu G.",
    review:
      "M-au ajutat să-mi pun website-ul online folosind AWS + atașarea unui domain. Am creat website-ul folosind python și nu știam cum să-l pun online, dar mi-a fost foarte ușor cu ajutorul lor.",
    rating: 5,
  },
  {
    name: "Laura B.",
    review:
      "Colaborarea cu voi pentru crearea unui portofoliu online a fost un succes. Site-ul meu arată profesional și mi-a adus mai multe oportunități de afaceri.",
    rating: 5,
  },
  {
    name: "Alex M.",
    review:
      "Am fost foarte mulțumit de serviciile voastre de creare a unui site de prezentare. Designul elegant și funcționalitățile avansate ne-au ajutat să ne evidențiem în piață.",
    rating: 5,
  },
  {
    name: "Răzvan G.",
    review:
      "Am beneficiat de un serviciu de punere pe hosting AWS excelent. Site-ul nostru funcționează perfect și am avut uptime maxim. Mulțumim pentru profesionalismul vostru!",
    rating: 5,
  },
  {
    name: "Florin B.",
    review:
      "Colaborarea cu voi pentru dezvoltarea unui website de e-commerce a fost remarcabilă. Funcționalitățile avansate și designul atrăgător ne-au ajutat să creștem vânzările.",
    rating: 5,
  },
];

export const technologies = [
  {
    name: "React",
    icon: react,
  },
  {
    name: "Python",
    icon: python,
  },
  {
    name: "Django",
    icon: django,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "HTML",
    icon: html,
  },
  {
    name: "CSS",
    icon: css,
  },
  {
    name: "TailwindCSS",
    icon: tailwindcss,
  },
  {
    name: "Bootstrap",
    icon: bootstrap,
  },
  {
    name: "SASS",
    icon: sass,
  },
  {
    name: "NodeJS",
    icon: nodejs,
  },

  {
    name: "Liquid",
    icon: liquid,
  },
  {
    name: "Flutter",
    icon: flutter,
  },
  {
    name: "Dart",
    icon: dart,
  },
  {
    name: "SQL",
    icon: sql,
  },
  {
    name: "Adobe Photoshop",
    icon: photoshop,
  },
  {
    name: "Adobe Premiere Pro",
    icon: premiere,
  },
];
