import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../functions/constants.js";
import plus from "../../static/images/plus.svg";
import AdminPackageCard from "../../components/AdminPackageCard.js";
import AddPackageQuestionsForm from "../../components/AddPackageQuestionsForm.js";
import AddPackageQuestionCard from "../../components/AddPackageQuestionCard.js";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../components/LoadingSpinner.js";
import PageNotLoaded from "../../components/PageNotLoaded.js";
import { useSelector } from "react-redux";
import get_auth_config from "../../functions/GetAuthConfig.js";

function AdminServicePackages() {
  const admin = useSelector((state) => state.admin);
  const config = get_auth_config(admin.token);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const id = useParams().id;
  const [service, setService] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packageIcon, setPackageIcon] = useState(null);
  const [packageQuestions, setPackageQuestions] = React.useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "service/" + id)
      .then((response) => {
        setService(response.data);
        axios
          .get(api_url + "service/" + id + "/packages")
          .then((response) => {
            setPackages(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setPageError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  function AddPackageHandler(e) {
    e.preventDefault();
    const data = e.target;
    const name = data.name.value;
    const button_message = data.button_message.value;
    const description = data.description.value;

    if (
      name === "" ||
      button_message === "" ||
      description === "" ||
      packageIcon === null ||
      packageQuestions.length === 0
    ) {
      alert("Toate câmpurile sunt obligatorii");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("button_message", button_message);
    formData.append("description", description);
    formData.append("icon", packageIcon);
    formData.append("questions", JSON.stringify(packageQuestions));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: admin.access,
      },
    };

    axios
      .post(api_url + "service/" + id + "/package", formData, config)
      .then((response) => {
        setPackages([...packages, response.data]);
        e.target.reset();
        setPackageIcon(null);
        setPackageQuestions([]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function RemovePackageHandler(e, id) {
    e.preventDefault();
    axios
      .delete(api_url + "service/" + service.id + "/package/" + id, config)
      .then((response) => {
        setPackages(
          packages.filter((list_package) => {
            return list_package.id !== id;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function AddPackageIconHandler(e) {
    e.preventDefault();
    const icon = e.target.files[0];
    const type = icon.type;
    if (!type.includes("svg")) {
      alert("Formatul imaginii trebuie să fie SVG");
      return;
    }
    setPackageIcon(icon);
  }

  function AddPackageQuestionsHandler(question) {
    if (question === "") {
      alert("Întrebarea nu poate fi goală");
      return;
    }
    const package_questions = packageQuestions.map(
      (package_question) => package_question.question
    );
    if (package_questions.includes(question)) {
      alert("Întrebarea deja există");
      return;
    }
    setPackageQuestions([...packageQuestions, question]);
  }

  function RemovePackageQuestionHandler(key) {
    const new_package_questions = packageQuestions.filter(
      (package_question, index) => {
        return index !== key;
      }
    );
    setPackageQuestions(new_package_questions);
  }

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div className="w-full px-16 mx-auto mt-8 xl:px-10 lg:px-10 md:px-10 sm:px-5 mb-10">
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <title>Admin | {service && service.name} | Listă pachete</title>
      </Helmet>
      <h1 className="w-fit text-2xl">
        {service && service.name} | Listă pachete
      </h1>
      <div className="flex justify-between mt-4 md:flex-col sm:flex-col">
        <div className="w-[47%] lg:w-1/2 pr-16 xl:pr-8 lg:pr-8 md:w-full md:pr-0 sm:pr-0 sm:w-full">
          <form
            className="flex justify-between xl:flex-col gap-y-4 lg:flex-col sm:flex-col"
            onSubmit={AddPackageHandler}
          >
            <div className="w-[47%] xl:w-full lg:w-full sm:w-full">
              <input
                type="text"
                className="regular-border w-full sm:!min-w-[15rem]"
                placeholder="Nume pachet"
                name="name"
              />
              <div className="flex justify-between mt-2">
                <input
                  type="file"
                  className="hidden"
                  id="packageIcon"
                  onChange={AddPackageIconHandler}
                />
                <label
                  htmlFor="packageIcon"
                  className="cursor-pointer w-fit block"
                >
                  <div className="h-40 w-40 regular-border relative">
                    <img
                      alt={"package-icon"}
                      src={
                        packageIcon == null
                          ? plus
                          : URL.createObjectURL(packageIcon)
                      }
                      className={
                        "top-1/2 absolute-center !fill-white" +
                        (packageIcon == null
                          ? " w-1/3"
                          : " w-2/3 !fill-white text-white")
                      }
                    />
                  </div>
                </label>
                <input
                  type="text"
                  name={"button_message"}
                  placeholder="Mesaj buton"
                  className="ml-2 block h-fit !min-w-0"
                />
              </div>
            </div>
            <div className="w-[47%] xl:w-full lg:w-full sm:w-full">
              <textarea
                maxLength={500}
                name="description"
                placeholder="Descriere"
                className="flex ml-auto w-full h-40 text-base placeholder:text-lg default-scroll sm:!min-w-[15rem]"
              ></textarea>
              <button className="regular-btn mt-2 flex ml-auto px-8 pt-1">
                Adaugă pachet
              </button>
            </div>
          </form>
          <AddPackageQuestionsForm AddHandler={AddPackageQuestionsHandler} />
          <div className="flex flex-col gap-y-2 mt-4 mb-4 select-text">
            {packageQuestions &&
              packageQuestions.map((question, index) => {
                return (
                  <AddPackageQuestionCard
                    removeHandler={RemovePackageQuestionHandler}
                    question={question}
                    id={index}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
        <div className="w-[47%] flex flex-col gap-y-4 md:w-full sm:w-full">
          {packages.length > 0
            ? packages.map((list_package) => {
                return (
                  <AdminPackageCard
                    questions={list_package.questions}
                    button_message={list_package.button_message}
                    id={list_package.id}
                    key={list_package.id}
                    icon={"data:image/svg+xml;base64," + list_package.icon}
                    name={list_package.name}
                    description={list_package.description}
                    removeHandler={RemovePackageHandler}
                  />
                );
              })
            : "Nu există pachete"}
        </div>
      </div>
    </div>
  );
}

export default AdminServicePackages;
