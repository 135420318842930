import React, { useState } from "react";
import phone_message from "../static/images/phone_message.svg";
import axios from "axios";
import { api_url } from "../functions/constants";
import { useDispatch } from "react-redux";
import { setError } from "../store/ErrorSlice";
import { motion } from "framer-motion";
import { social_media_primary } from "../functions/constants";
import { Link } from "react-router-dom";
function Contact() {
  const dispatch = useDispatch();
  const [messageSent, setMessageSent] = useState(false);
  function ContactHandler(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");
    if (!name || !email || !message) {
      dispatch(setError("Toate câmpurile sunt obligatorii!"));
      return;
    }

    axios
      .post(api_url + "contact", {
        name: name,
        email: email,
        message: message,
      })
      .then((response) => {
        setMessageSent(true);
        e.target.reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="px-24 mt-12 mb-24 xl:px-10 lg:px-10 md:px-10 sm:px-10">
      <h1 className="text-2xl font-bold lg:hidden">LUAȚI LEGĂTURA CU NOI</h1>
      <div className="flex justify-between gap-x-16 sm:flex-col sm:gap-y-12 md:flex-col md:gap-y-12 lg:flex-col lg:gap-y-12 lg:gap-x-0">
        <div className="w-[60%] lg:mx-auto lg:w-full sm:w-full">
          <h1 className="text-2xl font-bold hidden lg:block">
            LUAȚI LEGĂTURA CU NOI
          </h1>
          <p className="text-lg">
            Nu ezitați să ne contactați iar noi vă vom răspunde cât mai curând
            posibil.
          </p>
          <form className="mt-4 w-fit sm:w-full" onSubmit={ContactHandler}>
            <div className="flex gap-x-4 justify-between sm:flex-col sm:gap-y-4">
              <input type="text" required placeholder="Nume" name="name" />
              <input type="text" required placeholder="Email" name="email" />
            </div>
            <div className="relative">
              <textarea
                required
                className="mt-4 w-full min-h-[16rem]"
                placeholder="Mesaj"
                name="message"
              ></textarea>
              <motion.img
                initial={{ y: -100, translateX: "100%" }}
                animate={{ y: 0, transition: { duration: 0.5 } }}
                src={phone_message}
                className="sm:hidden md:hidden w-52 absolute right-0 top-0 translate-x-full"
              />
            </div>
            <button className="min-w-[20rem] regular-btn w-full pt-1 mt-2 sm:w-full">
              TRIMITE MESAJ
            </button>
            {messageSent && (
              <p className="min-w-[20rem] sm:w-full text-primary mt-4 bg-secondary pt-1 text-center">
                Mesajul a fost trimis cu succes!
              </p>
            )}
          </form>
        </div>
        <div className="w-[35%] xl:w-[27.5%] lg:w-[60%] lg:min-w-[41rem] md:min-w-[41rem] sm:w-full">
          <div className="w-full bg-secondary p-4 text-primary h-fit transition-all ease-in-out duration-300">
            <h2 className=" font-semibold text-xl">Informații de contact</h2>
            <div className="flex justify-between flex-col gap-y-8">
              <div className="flex flex-col gap-y-1 mt-2">
                <Link
                  to="tel:+40764456953"
                  className="text-primary select-text w-fit"
                >
                  <span className="font-semibold">Telefon 1: </span>+40 764 456
                  953
                </Link>
                <Link
                  to="tel:+37360842266"
                  className="text-primary select-text w-fit"
                >
                  <span className="font-semibold">Telefon 2: </span>+373 608 42
                  266
                </Link>
                <Link
                  to="mailto:office@scriptify.ro"
                  className="text-primary select-text w-fit"
                >
                  <span className="font-semibold">Email: </span>
                  office@scriptify.ro
                </Link>
              </div>
              <div className="grid grid-cols-4 gap-x-8 gap-y-4">
                {social_media_primary.map((item, index) => (
                  <Link to={item.link}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { delay: index * 0.25 },
                      }}
                      key={index}
                      className="flex flex-col items-center content-between gap-y-1"
                    >
                      <img src={item.icon} alt={item.alt} className="w-8" />
                      <div className="text-xs">{item.name}</div>
                    </motion.div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
