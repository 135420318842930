import React, { useState, useEffect } from "react";
import { setPage } from "../../store/AdminPageSlice";
import { useDispatch, useSelector } from "react-redux";
import GetGoogleDiveId from "../../functions/GetGoogleDriveId";
import { api_url } from "../../functions/constants";
import axios from "axios";
import Review from "../../components/Review";
const AdminHome = () => {
  const [videos, setVideos] = useState([]);
  const [reviews, setReviews] = useState([]);
  const admin = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPage("Setări pagina de start"));

    axios.get(api_url + "home/videos").then((response) => {
      setVideos(response.data);
    });
    axios.get(api_url + "home/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  function AddVideoHandler(e) {
    e.preventDefault();
    const video = e.target.video.value;
    const videoId = GetGoogleDiveId(video);
    const token = admin.access;

    axios
      .post(
        api_url + "home/video",
        { video: videoId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setVideos([...videos, response.data]);
        e.target.video.value = "";
      });
  }

  function AddReviewHandler(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const review = e.target.review.value;
    const token = admin.access;

    axios
      .post(
        api_url + "home/review",
        { name: name, review: review },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setReviews([...reviews, response.data]);
        e.target.name.value = "";
        e.target.review.value = "";
      });
  }

  return (
    <div className="px-20 gap-x-16 grid grid-cols-2 mt-4 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-2">
      <div className="">
        <h2 className="text-xl font-semibold text-dark">Listă videoclipuri</h2>
        <form
          onSubmit={AddVideoHandler}
          className="grid grid-cols-4 gap-x-4 gap-y-2"
        >
          <input
            name="video"
            type="text"
            placeholder="Link videoclip"
            className="col-span-3"
          />
          <button className="regular-btn  px-6 pt-1">Adaugă</button>
        </form>
        <div className="flex flex-col gap-y-4 mt-4">
          {videos.map((video) => (
            <div className="group regular-border relative pb-[54%]">
              <iframe
                src={`https://drive.google.com/file/d/${video.video}/preview`}
                className="w-full h-full absolute top-0 left-0 bottom-0 right-0"
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen="true"
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <h2 className="text-xl font-semibold text-dark">Listă recenzii</h2>
        <form onSubmit={AddReviewHandler} className="grid grid-cols-2 gap-x-4">
          <textarea
            name="review"
            type="text"
            placeholder="Recenzie"
            className="w-full h-32"
          />
          <div>
            <input
              name="name"
              type="text"
              placeholder="Nume"
              className="w-full h-fit"
            />
            <button className="regular-btn w-full px-16 mt-2 ml-auto block pt-1">
              Adaugă
            </button>
          </div>
        </form>
        <div className="flex flex-col gap-y-2 mt-4">
          {reviews.map((review) => (
            <Review fullWidth={true} review={{ ...review, rating: 5 }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
