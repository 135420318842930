import React from 'react'

function AdminPackageCardQuestion ({question, type}) {
  return (
    <div className='relative p-1'>
          <div className='font-semibold mb-1'>
              <span className='select-text text-primary inline-block bg-secondary whitespace-nowrap h-fit px-2 mr-2 pt-0.5'>
                  {type === "short" ? "Text scurt" : type === "long" ? "Text lung" : 
                  type === "options" ? "Variante multiple": "unknown"}
              </span>
              <span className='pt-0.5 select-text'>{question}</span>
          </div>
          {question === "options" ? <div className='flex flex-col gap-y-2'>
              {question.options.map((option, index) => {
                  return <div key={index} className='flex  px-2 justify-between items-center'>
                      <p className='text-dark select-text'>{option}</p>
                      </div>
              })}
        </div>: null}
    </div>
  )
}

export default AdminPackageCardQuestion