import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import ScriptifyAdminLogo from "../../static/images/scriptify-admin.svg";
import seoIcon from "../../static/images/seo-icon.svg";
import servicesIcon from "../../static/images/services-icon.svg";
import statisticsIcon from "../../static/images/statistics-icon.svg";
import portofolioIcon from "../../static/images/portofolio-icon.svg";
import logoutIcon from "../../static/images/logout-icon.svg";
import homeIcon from "../../static/images/home-icon.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
function Admin() {
  const location = useLocation().pathname;
  const [showMenu, setShowMenu] = useState(false);
  const pageName = useSelector((state) => state.adminPage);
  useEffect(() => {
    setShowMenu(false);
  }, [location]);
  const pages = [
    {
      name: "Acasă",
      icon: homeIcon,
      link: "/admin/home",
    },
    {
      name: "Servicii",
      icon: servicesIcon,
      link: "/admin/",
    },
    {
      name: "SEO",
      icon: seoIcon,
      link: "/admin/seo",
    },
    {
      name: "Portofoliu",
      icon: portofolioIcon,
      link: "/admin/portofolio",
    },

    {
      name: "Statistici",
      icon: statisticsIcon,
      link: "/admin/statistics",
    },
  ];

  return (
    <div className="w-screen">
      <div className="w-screen bg-secondary grid grid-cols-3 py-1 justify-between sm:grid-cols-2 gap-y-2 sm:py-2 rounded-b-2xl h-11 sm:h-auto items-center px-4">
        <img src={ScriptifyAdminLogo} className="h-6 sm:order-1" />
        <div className="text-primary text-center sm:text-sm sm:order-3 sm:col-span-2">
          {pageName}
        </div>
        <img src={logoutIcon} className="h-5 ml-auto sm:order-2" />
      </div>
      {/* <Link to="/admin" className="w-fit block mx-auto mt-4">
        <img
          src={ScriptifyAdminLogo}
          alt="Scriptify Admin Logo"
          className={" w-[30rem]"}
        />
      </Link> */}
      <div className="max-w-[100wh] w-full flex mt-12 overflow-x-hidden">
        <div
          onClick={() => setShowMenu(!showMenu)}
          className={
            "cursor-pointer font-bold bg-secondary text-primary h-fit mt-4 translate-x-[-100%] transition ease-in-out duration-300 rounded-r-full p-2 text-xl " +
            (showMenu ? "sm:translate-x-[-100%]" : "sm:translate-x-0")
          }
        >
          {">"}
        </div>
        <section
          className={
            "w-12 delay-200  transition ease-in-out duration-300 py-8 px-2 absolute rounded-r-full h-[75vh] bg-secondary flex flex-col gap-y-4 " +
            (showMenu ? "sm:translate-x-0" : "sm:translate-x-[-300%]")
          }
        >
          {pages.map((page, index) => (
            <Link to={page.link} key={index} className="group relative">
              <img
                className="p-1 transition-all ease-in-out duration-200 border-solid border-transparent border-1  group-hover:border-primary rounded-lg"
                src={page.icon}
              />
              <span className="translate-x-[-1rem] group-hover:translate-x-0 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out  absolute top-1/2 text-left translate-y-[-35%] left-[125%] bg-secondary text-primary rounded-r-md px-2 py-0.5 text-sm">
                {page.name}
              </span>
            </Link>
          ))}
        </section>
        <Outlet />
      </div>
    </div>
  );
}

export default Admin;
