import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url, media_url } from "../functions/constants";
import { motion } from "framer-motion";
import maximize from "../static/images/maximize.svg";
import maximize_white from "../static/images/maximize-white.svg";
import VideoFrame from "../components/VideoFrame";
import confirm from "../static/images/confirm.svg";
import { technologies } from "../functions/constants";
import x_dark from "../static/images/x-dark.svg";
const Portofolio = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios.get(api_url + "portofolio/categories").then((response) => {
      setCategories(response.data);
      if (response.data.length > 0) {
        setSelectedCategory(response.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(api_url + "portofolio/" + selectedCategory.id + "/items")
        .then((response) => {
          setProducts(response.data);
        });
    }
  }, [selectedCategory]);

  return (
    <div className="px-36 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-4">
      <h1 className="text-2xl font-bold text-dark">Portofoliu</h1>
      <p className="text-lg font-medium text-dark">
        Aici poți vedea produsele noastre finisate sau la care lucrăm. De la
        website-uri la aplicații mobile, de la design la dezvoltare și
        publicare.
      </p>
      <div className="flex gap-y-3 mt-2 gap-x-4 flex-wrap">
        {categories.map((category) => (
          <div
            key={category.id}
            onClick={() => setSelectedCategory(category)}
            className={
              "flex transition-colors cursor-pointer sm:w-full sm:justify-center ease-in-out duration-300 px-12 text-lg font-semibold py-2 items-center gap-x-2" +
              (selectedCategory?.id == category.id
                ? " bg-black/30 "
                : " bg-black/20 ")
            }
          >
            <img
              className="w-6 h-fit"
              src={media_url + category.icon}
              alt={category.name}
            />
            <h2 className="w-fit mt-1">{category.name}</h2>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-4 gap-x-8 gap-y-8">
        {products.map((product, index) => (
          <motion.div
            onClick={() => {
              setSelectedProduct(product);
              setIsProductOpen(true);
              document.body.style.overflow = "hidden";
            }}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
            whileTap={{ scale: 1, transition: { duration: 0.3 } }}
            key={product.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, opacity: { delay: index * 0.15 } }}
            className="flex group relative flex-col drop-shadow-lg cursor-pointer"
          >
            <img src={media_url + product.images[0].image} />{" "}
            <div className="bg-primary/0 absolute top-0 left-0 right-0 bottom-0 group-hover:bg-primary/20 group-hover:backdrop-blur-[3px] backdrop-blur-none ease-in-out duration-200 transition-all"></div>
            <div className="absolute-center w-full opacity-0 group-hover:opacity-100 ease-in-out duration-300 transition-opacity">
              <h3
                className={
                  "uppercase font-bold text-lg text-center w-full " +
                  (product.is_dark ? "text-black" : "text-white")
                }
              >
                {product.name}
              </h3>
              <img
                className="w-5 h-5 mx-auto"
                src={product.is_dark ? maximize : maximize_white}
              />
            </div>{" "}
          </motion.div>
        ))}
      </div>
      <motion.div
        initial={{ display: "none" }}
        animate={{ display: isProductOpen ? "block" : "none" }}
        transition={{ duration: 0.3, delay: isProductOpen ? 0 : 0.3 }}
        className="fixed top-0 right-0 bottom-0 left-0 "
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isProductOpen ? 1 : 0 }}
          onClick={() => {
            setIsProductOpen(false);
            document.body.style.overflow = "visible";
          }}
          className="absolute top-0 right-0 bottom-0 left-0 bg-black/30"
        ></motion.div>
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: isProductOpen ? "0%" : "100%" }}
          transition={{ type: "liniar" }}
          className="w-1/4 xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-full drop-shadow-lg overflow-y-auto default-scroll py-4 px-4 min-w-[25rem] bg-primary h-full bottom-0 right-0 top-0 absolute z-10"
        >
          <img
            onClick={() => {
              setIsProductOpen(false);
              document.body.style.overflow = "visible";
            }}
            className="w-6 cursor-pointer absolute z-50 top-4 right-4"
            src={x_dark}
          />
          <h3 className="text-center uppercase text-2xl font-bold">
            {selectedProduct?.name}
          </h3>
          {selectedProduct?.url && (
            <Link
              to={selectedProduct?.url}
              className="regular-btn text-center py-1 w-full block"
            >
              Accesează produsul live
            </Link>
          )}
          <div className="mt-2 flex flex-col gap-y-2 font-medium text-dark text-sm">
            {selectedProduct?.description.split("\n").map((paragraph) => (
              <p>{paragraph}</p>
            ))}
          </div>
          {selectedProduct?.technologies.length > 0 && (
            <div className="flex flex-col gap-y-2 mt-4">
              <h4 className="text-lg font-bold text-dark">Tehnologii</h4>
              <div className="flex flex-wrap gap-x-4 gap-y-4">
                {selectedProduct?.technologies.map((technology) => (
                  <div className="flex gap-x-2 items-center">
                    <img
                      className="w-5 h-5"
                      src={
                        technologies.find(
                          (tech) => tech.name == technology.name
                        ).icon
                      }
                    />
                    <p>{technology.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-2 mt-4">
            <h4 className="text-lg font-bold text-dark">Caracteristici</h4>
            <ul className="flex flex-col gap-y-1">
              {selectedProduct?.features.map((feature) => (
                <li className="flex gap-x-2 items-center">
                  <img className="w-4" src={confirm} />
                  {feature.name}
                </li>
              ))}
            </ul>
          </div>
          {selectedProduct?.images.length > 0 && (
            <div>
              <h4 className="text-lg font-bold text-dark mt-4">Imagini</h4>
              <div className="flex flex-col gap-y-3">
                {selectedProduct?.images.map((image) => (
                  <img
                    className="w-full h-full object-cover regular-border"
                    src={media_url + image.image}
                  />
                ))}
              </div>
            </div>
          )}
          {selectedProduct?.videos.length > 0 && (
            <div>
              <h4 className="text-lg font-bold text-dark mt-4">Videoclipuri</h4>
              <div className="flex flex-col gap-y-3">
                {selectedProduct?.videos.map((video) => (
                  <div className=" group regular-border relative pb-[54%]">
                    <iframe
                      src={`https://drive.google.com/file/d/${video.video}/preview`}
                      className="w-full h-full absolute top-0 left-0 bottom-0 right-0"
                      // style="width:100%;height:100%;position:absolute;left:0px;top:0px;"
                      frameborder="0"
                      width="100%"
                      height="100%"
                      allowfullscreen="true"
                    ></iframe>
                  </div>
                ))}
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Portofolio;
