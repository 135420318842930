import React from "react";
function AboutUs() {
  return (
    <div className="px-36 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-5 ">
      <h1 className="text-2xl font-bold">Misiunea Scriptify</h1>
      <p className="text-xl font-medium">
        Scriptify este dedicat dezvoltării de produse software ce îți vor
        transforma ideea în realitate.
      </p>
      <div className="mt-4 w-[60%] flex flex-col gap-y-2 lg:w-[80%] md:w-[90%] sm:w-full">
        <p>
          Bun venit la Scriptify, o echipă pasionată de transformarea ideilor
          tale în experiențe digitale remarcabile! Suntem aici pentru a oferi
          soluții web și mobile de înaltă calitate, adaptate afacerii tale:
        </p>
        <ul className="list-disc ml-5 flex gap-y-1 mt-2 mb-2 flex-col">
          <li>
            Crearea de aplicații web și mobile personalizate folosind tehnologii
            avansate
          </li>
          <li>
            Dezvoltarea de chatbot-uri inteligente ce pot răspunde la întrebări
            despre afacerea ta
          </li>
          <li>Crearea și promovarea brandului tău online</li>
          <li>Redactarea de oferte personalizate</li>
        </ul>
        <p>
          Cu o abordare atentă la detalii și un focus puternic pe experiența
          utilizatorului, suntem dedicați în a transforma viziunea ta în
          realitate digitală.
        </p>
        <p>
          Echipa noastră de profesioniști creativi și tehnicieni talentați este
          gata să-ți ofere soluții inovatoare și eficiente. Suntem aici să te
          ajutăm să-ți dezvolți afacerea și să te conectezi mai bine cu clienții
          tăi.
        </p>
        <p>
          Contactează-ne astăzi și hai să-ți transformăm ideea în realitate!
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
