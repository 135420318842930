import React, { useEffect, useState } from "react";
import logo from "../static/images/scriptify-logo.svg";
import scriptify_logo_color from "../static/images/scriptify-logo-c.svg";
import burger from "../static/images/burger.svg";
import x from "../static/images/x.svg";
import { Link, useLocation, Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import { social_media } from "../functions/constants";
function NavigationBar() {
  const location = useLocation().pathname;
  const [showMenu, setShowMenu] = useState(false);
  const isXL = useMediaQuery("(min-width: 1280px)");
  const is2XL = useMediaQuery("(min-width: 1536px)");

  useEffect(() => {
    let overflow_timeout;
    if (showMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      overflow_timeout = setTimeout(() => {
        document.body.style.overflowY = "unset";
      }, 250);
    }
    return () => {
      clearTimeout(overflow_timeout);
    };
  }, [showMenu]);

  return (
    <div className={"min-h-[100vh] flex flex-col "}>
      <nav className="xl:px-8 sm:mx-auto lg:px-4 md:px-2 md:w-full md:flex-col lg:w-full px-16 py-10 flex items-center justify-between lg:flex-col">
        <img
          src={burger}
          onClick={() => setShowMenu(true)}
          alt="Burger Menu"
          className="hidden sm:block absolute top-12 left-6 w-10 cursor-pointer"
        />
        <Link to="/">
          <img className="h-16" src={logo} alt="Scriptify Logo" />
        </Link>
        <ul className="md:w-full sm:hidden flex text-xl relative lg:mt-4 lg:flex lg:justify-between md:mt-4 md:flex md:justify-between lg:w-full md:flex-wrap md:gap-y-2">
          <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/services">Servicii</Link>
          </li>
          <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/portofolio">Portofoliu</Link>
          </li>
          {/* <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/technologies">Tehnologii</Link>
          </li> */}
          <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/about-us">Despre noi</Link>
          </li>
          <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/contact">Contacte</Link>
          </li>
          <li className="inline-block px-8 py-2 xl:px-4 lg:px-4 md:px-4">
            <Link to="/faq">Întrebări frecvente</Link>
          </li>

          <li
            className={
              "md:mx-auto inline-block mx-4 relative group regular-border duration-300 ease-in-out transition-all"
            }
          >
            <div
              className={
                "bottom-0 left-0 w-full absolute h-0 bg-secondary group-hover:h-full ease-in-out duration-200 transition-all" +
                (location === "/order" ? " h-full" : "")
              }
            ></div>
            <Link
              className={
                "block mt-1.5 px-4 bottom-0 left-0 w-full group-hover:mix-blend-lighten delay-150 py-0.5 group-hover:text-primary ease-in-out duration-200 transition-all " +
                (location === "/order" ? "text-primary mix-blend-lighten" : "")
              }
              to="/order"
            >
              Vizualizează comanda
            </Link>
          </li>
          <motion.div
            className={"absolute block h-0.75 bottom-2 bg-secondary"}
            animate={{
              width: is2XL
                ? location === "/services"
                  ? "4.5rem"
                  : location === "/portofolio"
                  ? "6.25rem"
                  : location === "/about-us"
                  ? "6.35rem"
                  : location === "/contact"
                  ? "5.35rem"
                  : location === "/faq"
                  ? "10.75rem"
                  : "0"
                : isXL
                ? location === "/services"
                  ? "4.1rem"
                  : location === "/portofolio"
                  ? "6rem"
                  : location === "/about-us"
                  ? "6.25rem"
                  : location === "/contact"
                  ? "5.35rem"
                  : location === "/faq"
                  ? "10.75rem"
                  : "0"
                : "0",
              left: is2XL
                ? location === "/services"
                  ? "1.75rem"
                  : location === "/portofolio"
                  ? "9.75rem"
                  : location === "/about-us"
                  ? "19.5rem"
                  : location === "/contact"
                  ? "29.5rem"
                  : location === "/faq"
                  ? "38.65rem"
                  : "0"
                : isXL
                ? location === "/services"
                  ? "0.95rem"
                  : location === "/portofolio"
                  ? "6.85rem"
                  : location === "/about-us"
                  ? "14.5rem"
                  : location === "/contact"
                  ? "22.6rem"
                  : location === "/faq"
                  ? "29.6rem"
                  : "0"
                : "0",
            }}
          ></motion.div>
        </ul>
      </nav>
      <motion.div
        initial={{ height: 0, display: "none" }}
        animate={
          showMenu
            ? { height: "100%", display: "block" }
            : {
                height: "0%",
                display: "none",
                transition: {
                  display: {
                    delay: 0.3,
                  },
                  height: {
                    duration: 0.3,
                  },
                },
              }
        }
        className="fixed bottom-0 left-0 overflow-y-auto scrollbar-none w-full bg-secondary z-50"
      >
        <img
          src={x}
          onClick={() => setShowMenu(false)}
          alt="Close Menu"
          className="absolute top-8 right-6 w-8 cursor-pointer"
        />
        <ul className="flex flex-col gap-y-8 w-full text-primary absolute-center text-2xl uppercase text-center">
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit w-fit mx-auto"
          >
            <Link to="/services">Servicii</Link>
          </li>
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit w-fit mx-auto"
          >
            <Link to="/portofolio">Portofoliu</Link>
          </li>
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit w-fit mx-auto"
          >
            <Link to="/about-us">Despre noi</Link>
          </li>
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit w-fit mx-auto"
          >
            <Link to="/contact">Contacte</Link>
          </li>
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit w-fit mx-auto"
          >
            <Link to="/faq">Întrebări frecvente</Link>
          </li>
          <li
            onClick={() => setShowMenu(false)}
            className="h-fit block p-2 pt-3 border-primary border-solid border-2 w-fit mx-auto"
          >
            <Link to="/order">Vizualizează comanda</Link>
          </li>
        </ul>
      </motion.div>
      <Outlet />
      <div className={"w-full mt-auto"}>
        <div
          className={
            "grid grid-cols-3 sm:grid-cols-1 md:px-8 sm:px-8 sm:gap-y-4 md:grid-cols-2 gap-x-4 justify-between px-16 py-6 w-full lg:px-4"
          }
        >
          <div className="sm:mx-auto sm:w-full sm:text-center">
            <img
              src={scriptify_logo_color}
              alt="Scriptify Logo"
              className="w-80 sm:mx-auto"
            />
            <p className="text-dark text-lg ml-2 ">
              TRANSFORMĂM IDEILE ÎN SUCCES
            </p>
          </div>
          <div className="flex md:order-3 md:mt-6 md:col-span-2 justify-center gap-x-16 lg:gap-x-6 items-center">
            {social_media.map((item, index) => {
              return (
                <Link to={item.link}>
                  <img
                    src={item.icon}
                    alt={item.alt}
                    className="cursor-pointer hover:scale-110 opacity-80 hover:opacity-100 ease-in-out duration-200 transition-all w-10 h-10"
                    key={index}
                  />
                </Link>
              );
            })}
          </div>
          <div className="text-right sm:text-center flex flex-col ml-auto sm:mx-auto">
            <p className="text-secondary text-2xl sm:mx-auto ml-auto">
              Informații contact
            </p>
            <Link
              to="tel:+40764456953"
              className="select-text w-fit text-dark text-lg sm:mx-auto ml-auto"
            >
              +40 764 456 953
            </Link>
            <Link
              to="tel:+37360842266"
              className="select-text w-fit text-dark text-lg sm:mx-auto ml-auto"
            >
              +373 608 42 266
            </Link>
            <Link
              to="mailto:office@scriptify.ro"
              className="select-text w-fit text-dark text-lg sm:mx-auto ml-auto"
            >
              office@scriptify.ro
            </Link>
          </div>
        </div>
        <div className="flex justify-between px-5 py-0.5text-secondary text-base  ">
          <p>
            © {new Date().getFullYear()} Scriptify. Toate drepturile rezervate.
          </p>
          <p>v1.52</p>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
