import React from "react";

const Review = ({ review, fullWidth = false }) => {
  return (
    <div
      className={
        " h-full flex flex-col " +
        (fullWidth
          ? "w-full"
          : "w-[40rem] md:w-[30rem] sm:w-[20rem] lg:w-[35rem] p-4 self-stretch")
      }
    >
      <div className=" regular-border">
        <div className="p-3">
          <p className="font-semibold text-lg">{review.name}</p>
          <p>{review.review}</p>
        </div>
        <p className="mt-auto bg-secondary px-3 py-1 text-right">
          {review.rating === 5
            ? "⭐⭐⭐⭐⭐"
            : review.rating === 4
            ? "⭐⭐⭐⭐"
            : review.rating === 3
            ? "⭐⭐⭐"
            : review.rating === 2
            ? "⭐⭐"
            : "⭐"}
        </p>
      </div>
    </div>
  );
};

export default Review;
