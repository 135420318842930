import React from 'react'

function AddPackageQuestionsForm({AddHandler}) {
    const [questionType, setQuestionType] = React.useState('short');
    const [questionOptions, setQuestionOptions] = React.useState([]);
    
    
    function AddPackageQuestionHandler(e){
        e.preventDefault();
        const question = e.target.package_question.value;
        const question_type = questionType;
        if(question === ''){
            alert('Trebuie să adăugați o întrebare');
            return;
        }
        if(question_type === "options"){
            if(questionOptions.length === 0){
                alert('Trebuie să adăugați cel puțin o opțiune');
                return;
            }
        }
        if(questionType !== "options"){
            setQuestionOptions([]);
        }
        const new_package_question = {
            question: question,
            type: question_type,
            options: questionOptions,
        }
        
        setQuestionOptions([]);
        AddHandler(new_package_question)
        e.target.reset();
    }

    function RemoveQuestionOptionHandler(e,key){
        e.preventDefault();
        const new_options = questionOptions.filter((option, index) => {
            return index !== key;
        })
        setQuestionOptions(new_options);
    }
    function AddQuestionOptionHandler(e){
        e.preventDefault();
        const option = e.target.option.value;
        if(option === ''){
            alert('Trebuie să adăugați o opțiune');
            return;
        }
        
        if(questionOptions.includes(option)){
            alert('Opțiunea deja există');
            return;
        }
        e.target.reset();
        const new_options = [...questionOptions, option];
        setQuestionOptions(new_options);
    }

  return (
    <div>
        <form id="package_question_form" className='mt-4' onSubmit={AddPackageQuestionHandler}>
            <input name={"package_question"} type='text' className='w-full sm:!min-w-[15rem]' placeholder='Întrebare pachet'/>
            <div className='flex justify-between mt-2 gap-x-2 lg:flex-col gap-y-2 sm:flex-col'>
                <select value={questionType} className='appearance-none h-fit rounded-none outline-none min-w-[15rem] w-[15rem] max-w-[15rem] lg:w-full lg:min-w-[100%] lg:max-w-[100%] sm:w-full sm:min-w-[100%] sm:max-w-[100%]' onChange={e=>setQuestionType(e.target.value)}>
                    <option value={"short"} className='bg-primary cursor-pointer'>Text scurt</option>
                    <option value={"long"} className='bg-primary'>Text Lung</option>
                    <option value={"options"} className='bg-primary'>Variante multiple</option>
                </select>
                <button className='regular-btn px-20 pt-1 xl:px-8 lg:w-full sm:w-full sm:px-0'>Adaugă Întrebare</button>
            </div>
        </form>
        {questionOptions.length > 0 && questionType === "options" &&
            <div className='w-[47%] mt-2 regular-border sm:w-full'>
                {questionOptions.map((option, index) => {
                    return <div key={index} className='flex  px-2 justify-between items-center'>
                        <p className='text-dark'>{option}</p>
                        <button onClick={(e)=>RemoveQuestionOptionHandler(e,index)} className='text-2xl leading-0'>x</button>
                    </div>
                })}
            </div>
        }
        {questionType === "options" && <form onSubmit={AddQuestionOptionHandler} className='flex gap-x-2 lg:w-full w-fit mt-2 sm:flex-col sm:w-full sm:gap-y-2'>
            <input name="option" type='text' className='w-fit sm:w-full sm:!min-w-[15rem]' placeholder='Variantă de răspuns'/>
            <button className='regular-btn w-fit px-4 pt-1 lg:grow sm:w-full'>Adaugă</button>
        </form>
        }
    </div>
  )
}

export default AddPackageQuestionsForm
