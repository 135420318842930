import React from 'react'
import {motion} from 'framer-motion'
import AdminPackageCardQuestion from './AdminPackageCardQuestion.js'
function AdminPackageCard ({icon, name, description , removeHandler, id, questions, button_message}) {
  const [showQuestions, setShowQuestions] = React.useState(false);

  return (
    <div className='regular-border w-full relative'>
      <div className='flex lg:flex-col px-4 sm:flex-col'>
        <img alt={"package-icon"} src={icon} className='h-40 w-40 py-5 pr-5 lg:mx-auto sm:mx-auto'/>
        <div className='py-2 w-[calc(100%-10rem)] lg:w-full sm:w-full'>
            <p className='text-xl font-semibold select-text'>{name}</p>
            <p className='font-normal select-text' dangerouslySetInnerHTML={{ __html: description.replace("\n", "<br/><br/>") }}/>
        </div>
      </div>
      <button onClick={(e)=>removeHandler(e, id)} className='text-2xl leading-0 absolute top-0 right-2'>x</button>
      <div className='flex items-baseline px-2 sm:flex-col gap-y-2'>
        <div onClick={()=>setShowQuestions(!showQuestions)} className='sm:order-1  font-medium cursor-pointer'>Vezi întrebări asociate:</div>
        <button className='regular-btn px-6 pt-1 ml-auto sm:w-full' disabled>{button_message}</button>
      </div>

      <motion.div initial={{height: 0}} animate={{height: showQuestions ? "auto" : "0"}} className='overflow-y-auto scrollbar-none flex flex-col gap-y-2 px-2 mt-2'>
        {questions ? questions.map((question, index) => {
          return <AdminPackageCardQuestion key={index} question={question.question} type={question.type}/>
      }): null}
      </motion.div>
    </div>
  )
}

export default AdminPackageCard
