import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../functions/constants";
import LeftColorButton from "../components/LeftColorButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPackageId } from "../store/PackageSlice";
import PageNotLoaded from "../components/PageNotLoaded";
import LoadingSpinner from "../components/LoadingSpinner";
import { motion } from "framer-motion";

function Serivce({ title, description }) {
  const id = useParams().id;
  const [packages, setPackages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "service/" + id + "/packages")
      .then((response) => {
        setPackages(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  function SelectPackageHandler(id) {
    dispatch(setPackageId(id));
    navigate("/package");
  }

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div className="px-36 mt-10 mb-24 w-full xl:px-10  lg:px-10 md:px-10 sm:px-4">
      <h1 className="text-2xl font-bold text-dark">{title}</h1>
      <p className="text-lg font-medium text-dark">{description}</p>
      <div className="grid grid-cols-3 mt-4 gap-x-14 gap-y-16 xl:gap-x-10 lg:gap-x-10 lg:grid-cols-2 md:grid-cols-2 md:gap-x-10 justify-between sm:grid-cols-1 ">
        {packages &&
          packages.map((list_package, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: index * 0.25 } }}
              key={list_package.id}
              className="min-w-[20rem] regular-border flex flex-col sm:w-full"
            >
              <img
                alt={list_package.name}
                className="h-48 mx-auto mt-4"
                src={"data:image/svg+xml;base64," + list_package.icon}
              />
              <h2 className="text-center px-4 text-xl mt-3 font-semibold">
                {list_package.name}
              </h2>
              <p
                className="px-4 mt-3 font-medium mb-4"
                dangerouslySetInnerHTML={{
                  __html: list_package.description.replace("\n", "<br/><br/>"),
                }}
              />
              <span
                className="block mt-auto"
                onClick={() => SelectPackageHandler(list_package.id)}
              >
                <LeftColorButton text_center={true}>
                  {list_package.button_message.toUpperCase()}
                </LeftColorButton>
              </span>
            </motion.div>
          ))}
      </div>
    </div>
  );
}

export default Serivce;
