import React, { useState, useEffect } from "react";
import axios from "axios";
import ribbon from "../static/images/ribbon.svg";
import { api_url } from "../functions/constants";
import { Link } from "react-router-dom";
import LeftColorButton from "../components/LeftColorButton";
import ServiceCard from "../components/ServiceCard";
import useMediaQuery from "../hooks/useMediaQuery";
import LoadingSpinner from "../components/LoadingSpinner";
import { motion } from "framer-motion";
import PageNotLoaded from "../components/PageNotLoaded";
function Services({ title, description }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const isLG = useMediaQuery("(max-width: 1279px)");
  const isMD = useMediaQuery("(max-width: 1023px)");
  const isSM = useMediaQuery("(max-width: 767px)");

  const [services, setServices] = useState(null);
  const [latestService, setLatestService] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}services/guarantees`)
      .then((res) => {
        let services = res.data;
        if (services.length % 2 === 0) setServices(services);
        else {
          setServices(services.slice(0, services.length - 1));
          setLatestService(services[services.length - 1]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setPageError(true);
        setIsLoading(false);
      });
  }, []);

  return pageError ? (
    <PageNotLoaded className="absolute-center">
      A apărut o problemă la încărcarea paginii. O vom rezolva cât mai curând
      posibil.
    </PageNotLoaded>
  ) : isLoading ? (
    <LoadingSpinner className={"absolute-center"} />
  ) : (
    <div>
      <div className="px-36 mt-10 mb-24 w-full xl:px-10 lg:px-10 md:px-10 sm:px-4">
        <h1 className="text-2xl font-bold text-dark">{title}</h1>
        <p className="text-lg font-medium text-dark">{description}</p>
        <div className="grid mt-4 sm:grid-cols-1 md:grid-cols-1 grid-cols-2 justify-between gap-x-14 gap-y-24 ">
          {services &&
            services.map((service, index) => (
              <ServiceCard
                index={index}
                key={service.id}
                id={service.id}
                discount_price={service.discount_price}
                name={service.name}
                description={service.description}
                starting_price={service.starting_price}
                icon={service.icon}
                guarantees={service.guarantees}
              />
            ))}
        </div>

        {!isLG && !isMD && !isSM && latestService ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: services.length * 0.25 },
            }}
            className="regular-border mt-24"
          >
            <div className="flex">
              <div className="p-4">
                <div className="h-40 w-40 py-5 px-5 bg-primary regular-border">
                  <img
                    alt={latestService.name}
                    src={"data:image/svg+xml;base64," + latestService.icon}
                    className="h-full  gap-4"
                  />
                </div>
              </div>
              <div className="w-[calc(100%-10rem-35%)] xl:w-[calc(100%-10rem-45%)]">
                <div className="p-4">
                  <h2 className="text-dark font-semibold text-2xl">
                    {latestService.name}
                  </h2>
                  <p
                    className="font-medium"
                    dangerouslySetInnerHTML={{
                      __html: latestService.description.replace(
                        "\n",
                        "<br/><br/>"
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="w-[calc(100%-10rem-(100%-10rem-35%))] xl:w-[calc(100%-10rem-(100%-10rem-45%))] bg-secondary relative flex flex-col">
                <div className="p-4">
                  <p className=" text-primary text-xl">
                    Pentru orice pachet garantăm:
                  </p>
                  <ul className="list-disc list-inside">
                    {latestService.guarantees &&
                      latestService.guarantees.map((guarantee) => (
                        <li className={"text-primary"} key={guarantee.id}>
                          {guarantee.name}
                        </li>
                      ))}
                  </ul>
                </div>
                <Link
                  className="block mt-auto"
                  to={"/service/" + latestService.id}
                >
                  <LeftColorButton>
                    ALEGE PACHETUL CARE ȚI SE POTRIVEȘTE
                  </LeftColorButton>
                </Link>
                {latestService.starting_price > 0 && (
                  <div className="absolute top-0 translate-y-[-1%] h-72 translate-x-[-50%] z-20 right-0">
                    <img
                      alt={"price-ribbon"}
                      src={ribbon}
                      className="h-full translate-y-[-3%]"
                    />
                    <div className="absolute-center text-center translate-y-[-75%] font-bold text-lg">
                      <div className="text-xl">
                        DE <br /> LA <br />
                      </div>
                      {latestService.discount_price > 0 ? (
                        <span>
                          €{latestService.discount_price}
                          <br />
                          <s className="text-lg">
                            €{latestService.starting_price}
                          </s>
                        </span>
                      ) : (
                        <span>€{latestService.starting_price}</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        ) : (
          latestService && (
            <div className="grid sm:grid-cols-1 md:grid-cols-1 grid-cols-2 justify-between gap-x-14 gap-y-24 mt-24">
              <ServiceCard
                discount_price={latestService.discount_price}
                index={services.length}
                key={latestService.id}
                id={latestService.id}
                name={latestService.name}
                description={latestService.description}
                starting_price={latestService.starting_price}
                icon={latestService.icon}
                guarantees={latestService.guarantees}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default Services;
